export const CATEGORY = {
    JING_ZU: {
        key: 1,
        name: '竞足投注助手'
    },
    REN_SHISI: {
        key: 2,
        name: '胜负彩投注助手'
    },
    REN_JIU: {
        key: 3,
        name: '任九投注助手'
    },
    BEI_TI: {
        key: 4,
        name: '北单投注助手'
    },
};

export const CATEGORY_LABEL_MAP = {};
CATEGORY_LABEL_MAP[CATEGORY.JING_ZU.key] = '竞彩';
CATEGORY_LABEL_MAP[CATEGORY.REN_SHISI.key] = '胜负彩';
CATEGORY_LABEL_MAP[CATEGORY.REN_JIU.key] = '任九';
CATEGORY_LABEL_MAP[CATEGORY.BEI_TI.key] = '北单';

export const Jing_ZU_CATEGORY = {
    SHEN_PING_FU: 1,
    RANG_QIU: 2,
    BI_FEN: 3,
    ZONG_JING_QIU: 4,
    BAN_QUAN_CHANG: 5,
    SHANG_XIA_PAN: 6,
};

export const JING_ZU_CATEGORY_LABEL = {};
JING_ZU_CATEGORY_LABEL[Jing_ZU_CATEGORY.SHEN_PING_FU] = '胜平负';
JING_ZU_CATEGORY_LABEL[Jing_ZU_CATEGORY.RANG_QIU] = '让球胜平负+1';
JING_ZU_CATEGORY_LABEL[Jing_ZU_CATEGORY.BI_FEN] = '比分';
JING_ZU_CATEGORY_LABEL[Jing_ZU_CATEGORY.ZONG_JING_QIU] = '总进球';
JING_ZU_CATEGORY_LABEL[Jing_ZU_CATEGORY.BAN_QUAN_CHANG] = '半全场';
JING_ZU_CATEGORY_LABEL[Jing_ZU_CATEGORY.SHANG_XIA_PAN] = '上下盘';

export const Jing_ZU_SUB_CATEGORY = {
    RANG_QIU: 1,
    BU_RANG_QIU: 2
};


export const BEI_TI_CATEGORY = {
  SHEN_PING_FU: 1,
  RANG_QIU: 2,
  BI_FEN: 3,
  ZONG_JING_QIU: 4,
  BAN_QUAN_CHANG: 5,
  SHANG_XIA_PAN: 6,
};

export const BEI_TI_SUB_CATEGORY = {
    RANG_QIU: 1,
    BU_RANG_QIU: 2
}

export const loginUrls = [
    '/user/userLogin',
    '/user/getVerifyCode'
];

export const REN_JIU_MAP = {
    '3': '胜',
    '1': '平',
    '0': '负',
};


export const BQC_MAP = ['胜胜','胜平','胜负','平胜','平平','平负','负胜','负平','负负'];
export const SPF_MAP = ['主胜', '平', '客胜'];

export const SHENG_PING_FU_MAP = {
    // 前端checkGroup里1对应后端的3 即： 胜 平 负 在后端定义为 3 2 1，在前端定义为 1 2 3；所以需要转换一下；
    '1': 3,
    '2': 1,
    '3': 0
}

export const SHENG_PING_FU_MAP2 = {
    '3': '主胜',
    '1': '平',
    '0': '客胜'
};

export const RATIO_KEY_MAP = {};
RATIO_KEY_MAP[Jing_ZU_CATEGORY.SHEN_PING_FU] = 'spf';
RATIO_KEY_MAP[Jing_ZU_CATEGORY.RANG_QIU] = 'rq';
RATIO_KEY_MAP[Jing_ZU_CATEGORY.BI_FEN] = 'bf';
RATIO_KEY_MAP[Jing_ZU_CATEGORY.ZONG_JING_QIU] = 'jq';
RATIO_KEY_MAP[Jing_ZU_CATEGORY.BAN_QUAN_CHANG] = 'bqc';
RATIO_KEY_MAP[Jing_ZU_CATEGORY.SHANG_XIA_PAN] = 'sxp';

export const PASS_WAY = {
  MN: 1,
  FREE: 2,
  COMPOSITION: 3,
};
