import React, {useEffect, useRef, useState} from 'react';
import styles from './index.module.less'
import ShengfuCaiItem from "./item";
import {Space, Toast, ActionSheet, Skeleton} from "@nutui/nutui-react";
import {TriangleDown} from "@nutui/icons-react";
import ShengfuCaiBottom from "./bottom";
import {checkValidData} from "./service";
import {CATEGORY} from "../../constant";
import {getIssues, getList, getUnitPrice, saveSelectedData} from "../../request";
import {calcMainAreaHeight} from "../../service";
import store, {getRenJiuStoreListByIssue, setRenJiuStore, setRenJiuStoreItem} from "../../store";
import { observer } from 'mobx-react';
import {sessionCache} from "../../utils/storage";
import RenJiuPoster from "./poster";
import NoData from "../widget/nodata/noData";

function RenJiu(props) {

    const [loading, setLoading] = useState(true);
    const [dateOption, setDateOption] = useState([]);
    const [curDate, setCurDate] = useState('');
    const [list, setList] = useState([]);

    const [actionSheetVisible, setActionSheetVisible] = useState(false);
    const [priceInfo, setPriceInfo] = useState({});
    const [posterInfo, setPosterInfo] = useState({ show: false, info: {} });

    const contentRef = useRef();
    const userResultRef = useRef();

    function setValueFn(id, value) {
      const curData = getRenJiuStoreListByIssue(curDate);
      const matchesList = curData.matchesList;
      // const handleType = checkWhetherAddOrMinus(matchesList, id, value);
      // if (handleType === 'minus') {
      //     setRenJiuStoreItem(curDate, id, value);
      // }
        const count = checkData(matchesList, id, value)
        if(count < 10) {
            setRenJiuStoreItem(curDate, id, value);
        }
      // setList(prevState => {
      //     if(!checkData(prevState, id, value)) {
      //         return prevState;
      //     }
      //     const item = prevState.find(it =>it.id === id);
      //     item.value = value;
      //     return [...prevState];
      // })
    }


    function checkData(dataGroup, id, curValue) {
        const data = JSON.parse(JSON.stringify(dataGroup));
        data.forEach(it => {
            if (it.id === id) {
                it.value = curValue;
            }
        });
        const count = data.filter(it => it.value.length > 0).length;
        if (count > 9) {
            Toast.show('只能选择9项比赛'); // todo 优化词条
        }
        return count;
    }

    function handleSubmit() {
        const curData = getRenJiuStoreListByIssue(curDate);

        const params = {
            userId: sessionCache.getUserInfo().userId,
            helperType: CATEGORY.REN_JIU.key,
            issue: curDate,
            startTime: curData.startTime,
            endTime: curData.endTime,
            drawTime: curData.drawTime,
            userResult: userResultRef.current,
            betsNum: priceInfo.betsNum,
            amount: priceInfo.amount,
            multi: 1,
            traditionalMatchesList: curData.matchesList.filter(it => it.value.length > 0)
        };
        saveSelectedData(params).then(data => {
            setPosterInfo({ show: true, info: params });
        });
    }

    function calcPrice() {
        const count = list.filter(it => it.value.length > 0).length;
        if (count !== 9) {
            setPriceInfo({});
        }  else {
            const result = list.map(it => {
                if (it.value.length === 0) {
                    return '-';
                } else {
                    return it.value.sort((a, b) => b - a).join('');
                }
            }).join(',');
            userResultRef.current = result;
            getUnitPrice({ helperType: CATEGORY.REN_JIU.key, userResult: result }).then(data => {
                setPriceInfo(data);
            })
        }
    }

    function genDataItems() {
        return list.map((info, index) => {
            return (
                <ShengfuCaiItem
                    key={index}
                    info={info}
                    value={info.value}
                    setValueFn={setValueFn}
                />
            )
        })
    }

    function handleSelectActionSheet(val) {
        setCurDate(val.id); // todo 与后端约定
        setActionSheetVisible(false);
        const data = getRenJiuStoreListByIssue(val.id);
        if (!data || !data.startTime) {
            getListByIssue(val.id);
        }
    }

    const setMainHeight = () => {
        const h = calcMainAreaHeight(['.lottery_header', '.sheng_fu_title', '.shenFuCai_settle_desk', '.nut-safe-area-position-bottom'])
        if(contentRef && contentRef.current) {
            contentRef.current.style.height = h;
        }
    };

    function getListByIssue(issue) {
        setLoading(true);
        getList({ helperType: CATEGORY.REN_JIU.key, issue: issue }).then(data => {
            if (data && data['matchesList']) {
                const matchList = data['matchesList'].map(it => {
                    it.value = [];
                    return it;
                });
                data['matchesList'] = matchList;
            }
            setRenJiuStore(issue, data);
        }).finally(() => {
            setLoading(false);
        })
    }

    useEffect(() => {
        setMainHeight();
        getIssues({ helperType: CATEGORY.REN_JIU.key }).then(data => {
            setDateOption(data.map(i => ({id: i, name: `${i}期`})));
            setCurDate(data[0]);
            return data;
        }).then(dateOpt => {
            getListByIssue(dateOpt[0]);
        })

    }, []);

    useEffect(() => {
        if (store.renJiuStore && curDate) {
            const curData = getRenJiuStoreListByIssue(curDate);
            if (curData && curData.matchesList && curData.matchesList.length > 0) {
                setList(curData.matchesList);
            } else {
                setList([]);
            }
        }
    }, [store.renJiuStore[curDate], curDate]);

    useEffect(() => {
        calcPrice();
    }, [list]);

    return (
        <div className={styles.warp}>
            <div className={styles.container}>
                <div className={`${styles.title} sheng_fu_title`}>
                    <span onClick={() => setActionSheetVisible(true)}>
                        <Space align={'center'}>
                        <span className={styles.date}>{curDate}期</span>
                        <TriangleDown width={14} height={14}/>
                    </Space>
                    </span>
                </div>
                <div className={styles.content} ref={contentRef}>
                    {
                        !loading && list.length > 0 &&
                        genDataItems()
                    }
                    {
                        !loading && list.length < 1 &&
                        <NoData style={{ height: '40vh'}}/>
                    }
                    {
                        loading &&
                        <div>
                            <Skeleton rows={3} title animated style={{marginBottom: '13px'}}/>
                            <Skeleton rows={3} title animated style={{marginBottom: '13px'}}/>
                            <Skeleton rows={3} title animated style={{marginBottom: '3px'}}/>
                        </div>
                    }
                </div>
            </div>
            <ShengfuCaiBottom
                list={list}
                submitFn={handleSubmit}
                disabled={!checkValidData(list)}
                priceInfo={priceInfo}
            />

            <ActionSheet
                title="请选择"
                visible={actionSheetVisible}
                options={dateOption}
                onSelect={handleSelectActionSheet}
                onCancel={() => setActionSheetVisible(false)}
            />

            {
                posterInfo.show &&
                <RenJiuPoster info={posterInfo.info}/>
            }
        </div>
    )

}

export default observer(RenJiu);
