import {getBeiTiStoreItem} from "../../store";
import {Toast} from "@nutui/nutui-react";
import {getJingZuStoreItem} from "../../store/jingZu";
import {
    BQC_MAP,
    Jing_ZU_CATEGORY,
    Jing_ZU_SUB_CATEGORY,
    RATIO_KEY_MAP,
    SHENG_PING_FU_MAP,
    SHENG_PING_FU_MAP2, SPF_MAP
} from "../../constant";

export function checkWhetherCanSetValue({ issue, id, category, silence = false}) {
    const item = getJingZuStoreItem(issue, id);
    if (item && item.checked && item.checked.filter(i => typeof i === 'number').length > 0 && item.category !== category) {
        if (!silence) {
            Toast.show({ content: '选择多种玩法，要再开一单进行选择哦' });
        }
        return false;
    }
    return true;
}

export function getSpecialCategoryList(data, category) {
    let allList = [];
    Object.keys(data).forEach(key => {
        if (data[key]) {
            allList = [...allList, ...data[key]];
        }
    });
    return allList.filter(it => it.category === category && it.checked.filter(i => typeof i === 'number').length > 0);
}

export function getSelectedItem(data) {
    let selectedList = [];
    Object.keys(data).forEach(key => {
        if (data[key]) {
            const arr = data[key].filter(it => it.checked.filter(i => typeof i === 'number').length > 0);
            selectedList = [...selectedList, ...arr];
        }
    });
    return selectedList
}

export function getSelectedItemCounts(data) {
    return getSelectedItem(data).length;
}

export function formatJingZuList(list) {
    let listClone = JSON.parse(JSON.stringify(list));
    listClone = listClone.map(item => {
        item.playType = item.category;
        if(item.subCategory === Jing_ZU_SUB_CATEGORY.RANG_QIU) {
            item.playType = Jing_ZU_CATEGORY.RANG_QIU;
        };
        const sellStatus = item.sell_status.split(',');
        item.sellStatus = sellStatus[item.playType];
        if ([Jing_ZU_CATEGORY.SHEN_PING_FU, Jing_ZU_CATEGORY.RANG_QIU].includes(item.playType)) {
            item.formatChecked = item.checked.map(val => SHENG_PING_FU_MAP[val+'']);
        }
        return item;
    });
    return spliceJingZuResult(listClone);
}

export function spliceJingZuResult(list) {
    return list.map(item => {
        if (item.playType === Jing_ZU_CATEGORY.SHEN_PING_FU) {
            item.result = item.checked.map(i => {
                let str = '';
                str = SPF_MAP[i-1] + ',' + getRatio(item, i-1);
                return str;
            }).join(';');
        }
        if (item.playType === Jing_ZU_CATEGORY.RANG_QIU) {
            item.result = '+1;' + item.checked.map(i => {
                let str = '';
                str = SPF_MAP[i-1] + ',' + getRatio(item, i);
                return str;
            }).join(';');
        }
        if (item.playType === Jing_ZU_CATEGORY.BI_FEN) {
            item.result = item.checked.map(i => {
                let str = '';
                str = `${i}:0` + ',' + getRatio(item, i);
                return str;
            }).join(';');
        }
        if (item.playType === Jing_ZU_CATEGORY.ZONG_JING_QIU) {
            item.result = item.checked.map(i => {
                let str = '';
                str = `${i === 7 ? '7+' : i}` + ',' + getRatio(item, i);
                return str;
            }).join(';');
        }
        if (item.playType === Jing_ZU_CATEGORY.BAN_QUAN_CHANG) {
            item.result = item.checked.map(i => {
                let str = '';
                str = BQC_MAP[i] + ',' + getRatio(item, i);
                return str;
            }).join(';');
        }
        return item;
    })
}

export function getRatio(item, checked) {
    const playType = item.playType;
    const ratioList = item[RATIO_KEY_MAP[playType]].split(',');
    return ratioList[checked];
}

export function getSelectedResultString(list) {
    let checked = [];
    list.forEach(item => {
        checked = [...checked, item.checked];
    })
}
