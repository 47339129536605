import React, {useEffect, useRef, useState} from 'react';
import {Collapse} from "@nutui/nutui-react";
import styles from './index.module.less'
import {ArrowDown} from "@nutui/icons-react";
import { observer } from 'mobx-react';
import BeiTiItem from "./item";
import MoreModeActionSheet from "./moreModeActionSheet";
import BeiTiSettlementDesk from "./shoppingCart/settlementDesk";
import store, {initBeiTiStore, setBeiTiStore} from "../../store";
import {calcMainAreaHeight} from "../../service";

const LENGTH = 6;

function BeiTi() {

    const [moreModeDialogInfo, setMoreModeDialogInfo] = useState({ show: false, id: '' });

    const contentRef = useRef();

    const updateChecked = ({id, category, subCategory, checked}) => {
        setBeiTiStore(id, { category, subCategory, checked })
    };

    const setMainHeight = () => {
        const h = calcMainAreaHeight(['.lottery_header', '.bei_ti_settle_desk', '.nut-safe-area-position-bottom'])
        if(contentRef && contentRef.current) {
            contentRef.current.style.height = h;
        }
    };

    useEffect(() => {
        initBeiTiStore([1,2,3,4,5,6]);
        setMainHeight();
    }, []);

    useEffect(() => {
    }, [store.beiTiStore]);

    return (
        <div className={styles.container}>
            <div className={styles.content} ref={contentRef}>
                {
                    Array(LENGTH).fill('').map((_, index) => {
                        return (
                            <Collapse defaultActiveName={['1', '2']} expandIcon={<ArrowDown />} key={index}>
                                <Collapse.Item title="2024-05-12 周六 27场比赛" name="1">
                                    <BeiTiItem
                                        value={store.beiTiStore.find(it =>it.id === index + 1)}
                                        id={index+1}
                                        setValueFn={updateChecked}
                                        setMoreModeDialogInfoFn={setMoreModeDialogInfo}
                                    />
                                </Collapse.Item>
                            </Collapse>
                        )
                    })
                }
            </div>

            <MoreModeActionSheet
                info={moreModeDialogInfo}
                setMoreModeDialogInfoFn={setMoreModeDialogInfo}
                setValueFn={updateChecked}
            />

            <BeiTiSettlementDesk/>
        </div>
    )

}

export default observer(BeiTi);
