import React from 'react';
import styles from "./index.module.less";
import {Col, Row} from "@nutui/nutui-react";
import {ColorPrimary, fontColorSubtype} from "../../theme/shortStyle";

export default function BeiTiTitle(props) {

 const { value } = props;

 return (
     <Row gutter="10" className={styles.top}>
         <Col span="5" style={{ textAlign: 'left'}}>
             <span className={styles.badge}>{value.comp}</span>&nbsp;
             {
                 value.ifShowDan &&
                 <span style={{color: ColorPrimary, verticalAlign: 'text-top'}}>单</span>
             }
         </Col>
         <Col span="15" style={{ textAlign: 'center'}}>
             <span style={fontColorSubtype}>[主]</span>&nbsp;
             <span style={{ fontWeight: 700 }}>
                        <span>{value.home}</span>&nbsp;VS&nbsp;<span>{value.away}</span>
                    </span>
         </Col>
     </Row>
 )
}
