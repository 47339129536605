import React from 'react';
import {Row, Col, Button, Space} from "@nutui/nutui-react";
import {ColumnFlexTopCenter, fontColorSubtype, RowFlexRightCenter, strongText} from "../../theme/shortStyle";
import styles from './index.module.less'

function getCheckedCount(list) {
    return list.filter(item => item.value.length > 0).length;
}

export default function ShengfuCaiBottom(props) {

 const { list, submitFn, disabled, priceInfo } = props;

 function handleSubmit() {
     submitFn();
 }



 return (
     <Row type={'flex'} className={`${styles.confirmArea} shenFuCai_settle_desk`} align={'center'}>
         <Col span={10} align={'center'}>
             {priceInfo.betsNum || 0}注
             <span style={strongText}>
             {priceInfo.amount || 0}
             </span>元
         </Col>
         <Col span={14} style={RowFlexRightCenter}>
             <Space align={'center'}>
                 <div style={ColumnFlexTopCenter}>
                     <span>已选{getCheckedCount(list)}</span>
                     <span style={{...fontColorSubtype, fontSize: '10px'}}>请选择14场比赛</span>
                 </div>
                 <Button size="large"  fill="solid" disabled={disabled} onClick={handleSubmit}>保存选择</Button>
             </Space>
         </Col>
     </Row>
 )
}
