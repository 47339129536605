import {getBeiTiStoreItem} from "../../store";
import {Toast} from "@nutui/nutui-react";

export function checkWhetherCanSetValue({id, category, silence = false}) {
    const item = getBeiTiStoreItem(id);
    if (item && item.checked && item.checked.filter(i => typeof i === 'number').length > 0 && item.category !== category) {
        if (!silence) {
            Toast.show({ content: '选择多种玩法，要再开一单进行选择哦' });
        }
        return false;
    }
    return true;
}

export function getSpecialCategoryList(list, category) {
    return list.filter(it => it.category === category && it.checked.filter(i => typeof i === 'number').length > 0);
}

export function getSelectedItemCounts(list) {
    return list.filter(it => it.checked.filter(i => typeof i === 'number').length > 0).length;
}
