import React, {useEffect, useState} from 'react';
import {Col, Divider, Toast, Badge} from "@nutui/nutui-react";
import styles from './index.module.less'
import {ColumnFlexCenterCenter} from "../../theme/shortStyle";
import BeiTiTitle from "./title";
import BeiTiShenPingFu from "./category/shenPingFu";
import { observer } from 'mobx-react';


function BeiTiItem(props) {

    const { id, value, setValueFn, setMoreModeDialogInfoFn } = props;
    const [count, setCount] = useState(0);


    const handleClickMoreModeBtn = () => {
        setMoreModeDialogInfoFn({ show: true, id });
    };

    useEffect(() => {
        if (value) {
            setCount(value.checked.filter(val => !!val).length);
        }
    }, [value]);


    return (
        <div >
            <BeiTiTitle/>
            <div className={styles.middle}>
                {`{周六001 05-18 14:00}`}
            </div>
            <BeiTiShenPingFu
                value={value || {}}
                setValueFn={setValueFn}
            >
                <Col span={2} align={'center'} onClick={handleClickMoreModeBtn}>
                    <div
                        className={styles.moreBtn}
                        style={ColumnFlexCenterCenter}>
                        <span className={styles.moreBtnContent}>更多玩法</span>
                        {
                            count > 0 &&
                            <Badge value={count} top={9} right={7}/>
                        }
                    </div>
                </Col>
            </BeiTiShenPingFu>
            <Divider style={{ margin: '12px 0'}}/>
        </div>
    )
}

export default observer(BeiTiItem);
