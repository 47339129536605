export const CACHE_KEY = {
    USER_INFO: 'userInfo',
}

class CacheTool {
    constructor(storageName = 'sessionStorage') {
        this.storage = window[storageName];
    }
    setItem(key, value) {
        this.storage.setItem(key, value);
    }
    getItem(key) {
        return this.storage.getItem(key);
    }
    removeItem(key) {
        this.storage.removeItem(key);
    }
    clear() {
        this.storage.clear();
    }
    setUserInfo(info) {
        this.setItem(CACHE_KEY.USER_INFO, JSON.stringify(info));
    }
    getUserInfo() {
        const res = this.getItem(CACHE_KEY.USER_INFO);
        if (res) {
            return JSON.parse(res);
        }
        return res;
    }
    removeUserInfo() {
        this.removeItem(CACHE_KEY.USER_INFO);
    }
}

export const sessionCache = new CacheTool('localStorage');
