import React, {useEffect} from 'react'
import {Route, Routes, Navigate, BrowserRouter} from 'react-router-dom'
import './App.less';
import '@nutui/nutui-react/dist/style.css'
import Index from "./pages/index";
import Login from "./pages/login";
import History from "./pages/history";

function App() {

    useEffect(() => {
        document.addEventListener('copy', function(event) {
            event.preventDefault(); // 阻止复制事件
        });
    }, []);

  return (
    <div className="App">
        <BrowserRouter>
            <Routes>
                <Route exact path='/' element={<Index/>} />
                <Route exact path='/login' element={<Login/>} />
                <Route exact path='/history' element={<History/>} />
                <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
