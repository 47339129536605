import React from 'react';
import styles from "../index.module.less";
import {Badge, Checkbox, Col, Row, Toast} from "@nutui/nutui-react";
import {ColumnFlexCenterCenter, fontColorWeight} from "../../../theme/shortStyle";
import { observer } from 'mobx-react';
import {useEffect, useState} from "react";
import {BEI_TI_CATEGORY, BEI_TI_SUB_CATEGORY} from "../../../constant";
import {checkWhetherCanSetValue} from "../service";



const options = [
    {
        value: 0,
        label: '让球'
    },
    {
        value: 1,
        label: '主胜'
    },
    {
        value: 2,
        label: '平'
    },
    {
        value: 3,
        label: '客胜'
    }
]

function Block(props) {
    const { option } = props;
    return (
        <Checkbox
            className="test"
            shape="button"
            value={option.value}
            disabled={option.value === 0}
            label={
                <div className={styles.block} style={{ textAlign: 'center'}}>
                    <span >{option.label}</span>
                    {
                        option.value !== 0 &&
                        <span >{`{1.34}`}</span>
                    }
                    {
                        option.type === 1 &&
                        <></>
                    }
                </div>
            }
        />
    )

}

function BeiTiShenPingFu(props) {

    const { showTitle, value, setValueFn, children } = props;

    const [checked1, setChecked1] = useState([]);
    const [checked2, setChecked2] = useState([]);


    const handleChange1 = (val) => {
        const pass = checkWhetherCanSetValue({ id: value.id, category: BEI_TI_CATEGORY.SHEN_PING_FU });
        if(!pass) return;

        setChecked1(prevState => {
            if (checked2.filter(val => !!val).length > 0) {
                Toast.show({ content: '选择多种玩法，要再开一单进行选择哦' })
                return prevState;
            } else {
                handleUpdateValue(val, checked2);
                return val;
            }
        });
    };
    const handleChange2 = (val) => {
        const pass = checkWhetherCanSetValue({ id: value.id, category: BEI_TI_CATEGORY.SHEN_PING_FU });
        if(!pass) return;

        setChecked2(prevState => {
            if (checked1.filter(val => !!val).length > 0) {
                Toast.show({ content: '选择多种玩法，要再开一单进行选择哦' })
                return prevState;
            } else {
                handleUpdateValue(checked1, val);
                return val;
            }
        });
    };

    const handleUpdateValue = (c1, c2) => {
        const checked1Count = c1.filter(val => !!val).length;
        const checked2Count = c2.filter(val => !!val).length;
        if (checked1Count > 0) {
            setValueFn({id: value.id, checked: c1, category: BEI_TI_CATEGORY.SHEN_PING_FU, subCategory: BEI_TI_SUB_CATEGORY.RANG_QIU});
        } else if (checked2Count > 0) {
            setValueFn({id: value.id, checked: c2, category: BEI_TI_CATEGORY.SHEN_PING_FU, subCategory: BEI_TI_SUB_CATEGORY.BU_RANG_QIU});
        } else {
            setValueFn({id: value.id, checked: [], category: BEI_TI_CATEGORY.SHEN_PING_FU, subCategory: BEI_TI_SUB_CATEGORY.RANG_QIU});
        }
    };


    useEffect(() => {
        if (Object.keys(value).length > 0) {
          if (value.category === BEI_TI_CATEGORY.SHEN_PING_FU) {
              if (value.subCategory === BEI_TI_SUB_CATEGORY.RANG_QIU) {
                  setChecked1(value.checked);
                  setChecked2([]);
                  return;
              }
              if (value.subCategory === BEI_TI_SUB_CATEGORY.BU_RANG_QIU) {
                  setChecked2(value.checked);
                  setChecked1([]);
                  return;
              }
          }
        }
        setChecked1([]);
        setChecked2([]);
    }, [value]);

    return (
        <>
            {
                showTitle &&
                <div style={{...fontColorWeight, textAlign: 'center', marginBottom: '14px'}}>胜平负</div>
            }
            <Row gutter="10" className={styles.bottom} align={'center'}>
                <Col span={22}>
                    <Row gutter={8}>
                        <Checkbox.Group
                            value={checked1}
                            onChange={handleChange1}>
                            {
                                options.map(option => {
                                    return (
                                        <Col key={option.value} span={option.value === 0 ? 6 : 6} >
                                            <Block option={option}/>
                                        </Col>
                                    )
                                })
                            }
                        </Checkbox.Group>
                    </Row>
                    <Row gutter={8}>
                        <Checkbox.Group
                            value={checked2}
                            onChange={handleChange2}>
                            {
                                options.map(option => {
                                    return (
                                        <Col key={option.value} span={option.value === 0 ? 6 : 6} >
                                            <Block option={option}/>
                                        </Col>
                                    )
                                })
                            }
                        </Checkbox.Group>
                    </Row>
                </Col>
                {
                    !!children &&
                    children
                }
            </Row>
        </>
    )

}

export default  observer(BeiTiShenPingFu);
