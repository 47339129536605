import React from 'react';
import styles from './index.module.less';
import ShengfuCai from "../shengfuCai";
import {CATEGORY} from "../../constant";
import RenJiu from "../renJiu";
import BeiTi from "../beiTi";
import JingZu from "../jingZu";



export default function Main(props) {

    const { category } = props;

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                {
                    category === CATEGORY.REN_JIU.key &&
                    <RenJiu/>
                }
                {
                    category === CATEGORY.REN_SHISI.key &&
                    <ShengfuCai/>
                }
                {
                    category === CATEGORY.BEI_TI.key &&
                    <BeiTi/>
                }
                {
                    category === CATEGORY.JING_ZU.key &&
                    <JingZu />
                }
            </div>
        </div>
    )
}
