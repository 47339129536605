import React, {useEffect, useState} from 'react';
import {ColorPrimary, fontColorWeight} from "../../../theme/shortStyle";
import styles from '../index.module.less';
import {Checkbox, ConfigProvider, Space} from "@nutui/nutui-react";
import {checkWhetherCanSetValue} from "../service";
import {Jing_ZU_CATEGORY} from "../../../constant";


function genOptions(jq) {
    if(!jq) return [];
    const opts = jq.split(',');
    const options = [];
    for (let i=0; i<8; i++) {
        const obj = {
            value: i,
            label: i === 7 ? '7+' : i,
            ratio: opts[i]
        }
        options.push(obj);
    }
    return options;
}

export default function BeiTiZongJinQiu(props) {
    const { showTitle, issue, value, setValueFn } = props;

    const [ checked, setChecked ] = useState([]);

    const sellStatus = value.sell_status.split(',');

    const handleChange = (val) => {
        const pass = checkWhetherCanSetValue({ issue, id: value.id, category: Jing_ZU_CATEGORY.ZONG_JING_QIU });
        if (pass) {
            setChecked(val);
            setValueFn({ issue, id:value.id, category: Jing_ZU_CATEGORY.ZONG_JING_QIU, subCategory: '', checked: val });
        }
    };

    useEffect(() => {
        if (Object.keys(value).length > 0) {
            const pass = checkWhetherCanSetValue({ issue, id: value.id, category: Jing_ZU_CATEGORY.ZONG_JING_QIU, silence: true });
            if (pass) {
                setChecked(value.checked);
                return;
            }
        }
        setChecked([]);
    }, [value]);

    return (
        <div className={styles.zongJinQiuContainer}>
            {
                showTitle &&
                <div style={{...fontColorWeight, textAlign: 'center', marginBottom: '14px'}}>
                    总进球
                    {
                        sellStatus[4] === 2 &&
                        <span style={{color: ColorPrimary, verticalAlign: 'bottom', marginLeft: '3px'}}>单</span>
                    }
                </div>
            }
            <Checkbox.Group
                value={checked}
                onChange={handleChange}>
                <div className={styles['zongJinQiuContainer-content']}>
                    {
                        genOptions(value.jq).map((option, index) => {
                            return (
                                <div key={index}>
                                    <Checkbox
                                        className="bi-fen-checkbox"
                                        shape="button"
                                        value={option.value}
                                        label={
                                            <div  style={{ textAlign: 'center'}}>
                                                <ConfigProvider
                                                    theme={{
                                                        nutuiSpaceGap: '2px',
                                                    }}
                                                >
                                                    <Space direction="vertical">
                                                        <span >{option.label}</span>
                                                        <span className={styles.ratio} style={{ color: checked.includes(option.value) ? '#fff' : '#8a8a8a'}}>{option.ratio}</span>
                                                    </Space>
                                                </ConfigProvider>
                                            </div>
                                        }
                                    />
                                </div>
                            )
                        })
                    }
                </div>
            </Checkbox.Group>
        </div>
    )
}
