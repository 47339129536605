import React, {useState} from 'react';
import {SafeArea} from "@nutui/nutui-react";
import Header from "../../components/header";
import Main from "../../components/main";
import styles from './index.module.less';
import {CATEGORY} from "../../constant";


export default function  Index() {

    const [category, setCategory ]= useState(CATEGORY.REN_SHISI.key)

    return (
        <div className={styles.container}>
            <Header category={category} setCategoryFn={setCategory}/>
            <Main category={category}/>
            <SafeArea position="bottom"/>
        </div>
    )
}
