import React from 'react';
import { createPortal } from 'react-dom';
import { Row, Col } from "@nutui/nutui-react";


import styles from './index.module.less'
import {fontColorWeight} from "../../theme/shortStyle";

const textAlignCenter = { textAlign: 'center' };
const paddingStyle = { padding: '7px' };
const cellStyle = {
    ...textAlignCenter,
    ...paddingStyle,
};
const fontLight = {
    color: '#8A8A8A',
};
const miniLightFont = {
    color: '#8A8A8A',
    fontsize: '10px',
};

function PosterBase() {
    return (
        <div className={styles.base}>
            <div className={styles.item}>
                <div className={styles.block}>
                    <div>
                        <span className={styles.number}>3</span>
                        <span className={styles.unit}>场</span>&nbsp;&nbsp;
                        <span className={styles.number}>2</span>
                        <span className={styles.unit}>关</span>
                    </div>
                    <div className={styles.label}>过关</div>
                </div>
                <div className={styles.block}>
                    <div>
                        <span className={styles.number}>2</span>
                        <span className={styles.unit}>倍</span>
                    </div>
                    <div className={styles.label}>倍数</div>
                </div>
            </div>
            <div className={styles.item}>
                <div className={styles.block}>
                    <div>
                        <span className={styles.number}>3</span>
                        <span className={styles.unit}>注</span>&nbsp;&nbsp;
                        <span className={styles.number}>12</span>
                        <span className={styles.unit}>元</span>
                    </div>
                    <div className={styles.label}>注数</div>
                </div>
                <div className={styles.block}>
                    <div className={styles.number}>
                        21.4～88.11
                    </div>
                    <div className={styles.label}>预计金额</div>
                </div>
            </div>
        </div>
    )
}

function PosterDivider() {
    return (
        <div className={styles.divider}>
            <div className={styles.round1}></div>
            <div className={styles.liner}></div>
            <div className={styles.round2}></div>
        </div>
    )
}

function PosterDetail() {

    const columns = [
        {
            title: '场次',
            key: 'name',
        },
        {
            title: '选择',
            key: 'gender',
            render: (record) => {
                return (
                    <div>1</div>
                )
            },
        },
        {
            title: '赛果',
            key: 'record',
        },
    ];

    const data = [
        {
            name: 'Tom',
            gender: '男',
            record: '小学',
        },
        {
            name: 'Lucy',
            gender: '女',
            record: '本科',
        },
        {
            name: 'Jack',
            gender: '男',
            record: '高中',
        }
    ];



    return (
        <div className={styles.detail}>
            <div className={styles.detailTitle}>投注详情</div>
            <div className={styles.table}>
                <Row className={styles.tableHeader}>
                    <Col span={8} style={{ ...cellStyle }}>场次</Col>
                    <Col span={12} style={{ ...cellStyle}}>选择</Col>
                    <Col span={4} style={{ ...cellStyle}}>赛果</Col>
                </Row>
                <Row className={styles.tableHeader}>
                    <Col span={8} style={{ ...cellStyle, ...fontLight}}>
                        周六001[主]
                    </Col>
                    <Col span={12} style={{ ...cellStyle, ...fontColorWeight }}>
                        斯图加特 VS 门兴
                    </Col>
                    <Col span={4} style={{ ...cellStyle}}></Col>
                </Row>
                <Row className={styles.tableHeader}>
                    <Col span={8} style={{ ...cellStyle, ...fontLight}}>
                        胜负平
                    </Col>
                    <Col span={12} style={{ ...cellStyle, ...fontColorWeight }}>
                        <span>平</span>
                        <span style={miniLightFont}>(3.25)</span>
                    </Col>
                    <Col span={4} style={{ ...paddingStyle }}></Col>
                </Row>
            </div>
        </div>
    )
}

export default function BeiTiPoster(props) {

    return createPortal(
        <div className={styles.posterContainer}>
            <div className={styles.title}>
                模拟投注详情
            </div>
            <div className={styles.main}>
                <div className={styles.top}>
                    北单足球
                    { // todo 名称确认
                    }
                </div>
                <div className={styles.content}>
                    <PosterBase/>
                    <PosterDivider/>
                    <PosterDetail/>
                </div>
            </div>
        </div>,
        document.body
    )

}
