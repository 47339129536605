import React, {useEffect, useRef, useState} from 'react';
import { createPortal } from 'react-dom';
import { Table, Button, Image } from "@nutui/nutui-react";
import html2canvas from 'html2canvas';
import logoPng from '@/assets/le_fu_logo.png';
import QRCode from 'qrcode';
import {sessionCache} from "../../utils/storage";



import styles from './index.module.less'
import {
    ColorPrimary,
    ColumnFlexCenterCenter, ColumnFlexTopSpaceAround,
    fontColorSubtype,
    fontColorWeight,
    RowFlexCenterCenter, RowFlexJustifyCenter,
    strongText
} from "../../theme/shortStyle";
import {REN_JIU_MAP} from "../../constant";

function formatInfo(info) {
    if (info.traditionalMatchesList[0].value) {
        return info;
    } else {
        const resultArr = info.userResult.split(',');
        info.traditionalMatchesList.forEach((item, index) => {
            item.value = resultArr[index].split('');
        });
        return info;
    }
}

function PosterBase(props) {
    const { info } = props;
    return (
        <div className={styles.base} style={ColumnFlexCenterCenter}>
            <div>
                <span className={styles.number} style={fontColorWeight}>{info.betsNum}</span>
                <span className={styles.unit}>注</span>&nbsp;&nbsp;
                <span className={styles.number} style={fontColorWeight}>{info.amount}</span>
                <span className={styles.unit}>元</span>
            </div>
            <div className={styles.label}>注数</div>
        </div>
    )
}

function PosterDivider() {
    return (
        <div className={styles.divider}>
            <div className={styles.round1}></div>
            <div className={styles.liner}></div>
            <div className={styles.round2}></div>
        </div>
    )
}

function PosterDetail(props) {

    const { info } = props;

    const columns = [
        {
            title: '场次',
            key: 'name',
            width: 68,
            render: (record, index) => {
                return (
                    <div style={fontColorSubtype}>{index + 1}</div>
                )
            },
        },
        {
            title: '对阵',
            key: 'gender',
            render: (record) => {
                return (
                    <div style={fontColorWeight}>{record.home}&nbsp;VS&nbsp;{record.away}</div>
                )
            },
        },
        {
            title: '选择',
            key: 'gender',
            render: (record) => {
                return (
                    <div style={fontColorSubtype}>
                        {
                            record.value.map(val => REN_JIU_MAP[val + '']).join(',')
                        }
                    </div>
                )
            },
        },
        {
            title: '赛果',
            key: 'record',
            render: () => {
                return <div style={fontColorSubtype}>-</div>
            }
        },
    ];

    return (
        <div className={styles.detail}>
            <div className={styles.detailTitle} style={fontColorWeight}>投注详情</div>
            <div className={styles.table}>
                <Table columns={columns} data={info.traditionalMatchesList} striped bordered={false}/>
            </div>
        </div>
    )
}


export default function RenShiSiPoster(props) {

    let { info } = props;

    info = formatInfo(info);

    const [qrCodeGenLoading, setQrCodeGenLoading] = useState(true);
    const [qrCode, setQrCode] = useState('');
    const posterRef = useRef();

    const handleSavePoster = () => {
        if (posterRef.current) {
            html2canvas(posterRef.current).then(function(canvas) {
                const data = canvas.toDataURL( 'image/png', 1 );
                const a = document.createElement('a');
                a.href = data;
                a.download = '胜负彩' + info.issue + '期模拟投注.png';
                a.click();
                window.URL.revokeObjectURL(data);
            })
        }
    };

    const genQrCode = () => {
        setQrCodeGenLoading(true);
        const loginUrl = window.location.origin + `/login?t=d&c=${sessionCache.getUserInfo().code}`;
        QRCode.toDataURL(loginUrl).then( base64 => {
            setQrCodeGenLoading(false);
            setQrCode(base64);
        });
    };

    const handleReload = () => {
        window.location.reload();
    };

    useEffect(() => {
        genQrCode();
    }, []);

    return createPortal(
        <div style={{ backgroundColor: ColorPrimary }}>
            <div className={styles.postWarp}>
                <div className={styles.posterContainer} ref={posterRef}>
                    <div className={styles.title} style={RowFlexJustifyCenter}>
                        <div style={RowFlexCenterCenter}>
                            <Image
                                width={48}
                                height={48}
                                src={logoPng}
                                radius={12}
                                style={{ marginRight: '8px'}}
                            />
                            <div style={{...ColumnFlexTopSpaceAround, height: '48px'}}>
                                <div style={{ fontSize: '16px', ...fontColorWeight, color: '#fff', textAlign: 'left' }}>乐富体育</div>
                                <div style={{ fontSize: '12px' }}>长按识别二维码 关注他的方案</div>
                            </div>
                        </div>
                        <div>
                            <Image
                                width={68}
                                height={68}
                                src={qrCode}
                            />
                        </div>
                    </div>
                    <div className={styles.main}>
                        <div className={styles.top}>
                            任九{info.issue}期
                        </div>
                        <div className={styles.content}>
                            <PosterBase info={info}/>
                            <PosterDivider/>
                            <PosterDetail info={info}/>
                            <div className={styles.tips} style={{...strongText, fontSize: '10px' }}>
                                *投注助手不能购买彩票，如需购买，请到线下彩票站
                            </div>
                            <div className={styles.bottom} style={RowFlexCenterCenter}>
                                <div className={styles.round3}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${styles.btnGroup} poster-btn-group`} style={RowFlexCenterCenter}>
                <Button type="primary" fill="outline" style={{ marginRight: '16px'}} onClick={handleReload}>再来一单</Button>
                <Button type="primary" onClick={handleSavePoster} loading={qrCodeGenLoading}>保存</Button>
            </div>
        </div>,
        document.body
    )

}
