import React, {useEffect, useState} from 'react';
import styles from '../index.module.less'
import {ActionSheet, Divider, Row, Col} from "@nutui/nutui-react";
import {Minus, CartRemove} from '@nutui/icons-react'
import {ColumnFlexCenterCenter, fontColorWeight, RowFlexLeftCenter} from "../../../theme/shortStyle";
import {BEI_TI_CATEGORY, BEI_TI_SUB_CATEGORY} from "../../../constant";
import store, {clearBeiTiStoreChecked, updateBeiTiStoreItemChecked} from "../../../store";
import {getSelectedItemCounts, getSpecialCategoryList} from "../service";
import { observer } from 'mobx-react';
import {toJS} from "mobx";

function Title (props) {
    return (
        <div>
            <div style={{...RowFlexLeftCenter}}>
                <span>[主]</span>
                <span>「斯图特」</span>
                <span>VS</span>
                <span>「门兴」</span>
                <span className={styles.badge}>德甲</span>
            </div>
            <div className={styles.date}>
                周六001 5月18 12:24
            </div>
        </div>
    )
}

function ShenPingFu(props) {

    const { data } = props;

    const handleDeleteItem = (id, deleteCheckedItem) => {
        updateBeiTiStoreItemChecked(id, deleteCheckedItem);
    };

    return (
        <div>
            <Title/>
            <div style={{...RowFlexLeftCenter, flexWrap: 'wrap'}} >
                {
                    data.checked.map((checked) => {
                        return (
                            <div key={checked} style={RowFlexLeftCenter}  className={styles.block}>
                                <div style={ColumnFlexCenterCenter} className={styles.category}>
                                    <div>让球</div>
                                    <div className={styles.value}>
                                        { data.subCategory === BEI_TI_SUB_CATEGORY.RANG_QIU ? '0' : '-1' }
                                    </div>
                                </div>
                                <div style={ColumnFlexCenterCenter} className={styles.main}>
                                    <div>客胜</div>
                                    <div className={styles.value}>2.82</div>
                                </div>
                                <Minus className={styles.deleteIcon} onClick={() => handleDeleteItem(data.id, checked)}/>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

function BiFen(props) {
    const { data } = props;

    const handleDeleteItem = (id, deleteCheckedItem) => {
        updateBeiTiStoreItemChecked(id, deleteCheckedItem);
    };

    return (
        <div>
            <Title/>
            <div style={{...RowFlexLeftCenter, flexWrap: 'wrap'}} >
                {
                    data.checked.map(checked => {
                        return (
                            <div key={checked} style={{...RowFlexLeftCenter, width: '80px'}} className={styles.block}>
                                <div style={ColumnFlexCenterCenter} className={styles.main}>
                                    <div>2:0</div>
                                    <div className={styles.value}>1.39</div>
                                </div>
                                <Minus className={styles.deleteIcon} onClick={() => handleDeleteItem(data.id, checked)}/>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

function ZongJinQiu(props) {
    const { data } = props;

    const handleDeleteItem = (id, deleteCheckedItem) => {
        updateBeiTiStoreItemChecked(id, deleteCheckedItem);
    };
    return (
        <div>
            <Title/>
            <div style={{...RowFlexLeftCenter, flexWrap: 'wrap'}} >
                {
                    data.checked.map(checked => {
                        return (
                            <div key={checked} style={{...RowFlexLeftCenter, width: '80px'}}  className={styles.block}>
                                <div style={ColumnFlexCenterCenter} className={styles.main}>
                                    <div>2:0</div>
                                    <div className={styles.value}>1.39</div>
                                </div>
                                <Minus className={styles.deleteIcon} onClick={() => handleDeleteItem(data.id, checked)}/>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

function BanQuanChang(props) {
    const { data } = props;

    const handleDeleteItem = (id, deleteCheckedItem) => {
        updateBeiTiStoreItemChecked(id, deleteCheckedItem);
    };

    return (
        <div>
            <Title/>
            <div style={{...RowFlexLeftCenter, flexWrap: 'wrap'}} >
                {
                    data.checked.map(checked => {
                        return (
                            <div key={checked} style={{...RowFlexLeftCenter, width: '80px'}} className={styles.block}>
                                <div style={ColumnFlexCenterCenter} className={styles.main}>
                                    <div>胜平</div>
                                    <div className={styles.value}>1.39</div>
                                </div>
                                <Minus className={styles.deleteIcon} onClick={() => handleDeleteItem(data.id, checked)}/>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

function BeiTiSelectedListActionSheet(props) {

    const { visible, setVisibleFn } = props;

    const [title, setTitle ] = useState('已选{}项');

    const handleSelectActionSheet = () => {

    };

    const handleClearAllChecked = () => {
        clearBeiTiStoreChecked();
    };

    const totalLength = getSelectedItemCounts(store.beiTiStore);
    const shenPingFuList = getSpecialCategoryList(store.beiTiStore, BEI_TI_CATEGORY.SHEN_PING_FU);
    const biFenList = getSpecialCategoryList(store.beiTiStore, BEI_TI_CATEGORY.BI_FEN);
    const zongJinQiuList = getSpecialCategoryList(store.beiTiStore, BEI_TI_CATEGORY.ZONG_JING_QIU);
    const banQuanChangList = getSpecialCategoryList(store.beiTiStore, BEI_TI_CATEGORY.BAN_QUAN_CHANG);

    useEffect(() => {
       if (store.beiTiStore.every(item => item.checked && item.checked.length === 0)) {
           setVisibleFn(false);
       }
    }, [store.beiTiStore]);

    const renderSpecialItems = (totalLength, list, ItemComp) => {
        return list.map((item, index) => {
            return (
                <div key={item.id}>
                    {<ItemComp data={item} />}
                    { // todo 分割线展示逻辑不对
                        list.length > 0 && index < list - 1 &&
                        <Divider className={styles.divider}/>
                    }
                </div>
            )
        })
    }

    return (
        <ActionSheet
            title={''}
            visible={visible}
            onSelect={handleSelectActionSheet}
            onCancel={() => setVisibleFn(false)}
        >
            <div className={styles.selectedListContainer}>
                <Row className={styles.title} align={'center'} type={'flex'}>
                    <Col span={8} className={styles.btnClean} onClick={handleClearAllChecked}>
                        <CartRemove
                            style={{ marginBottom: '-4px'}}
                        />
                        清除全部
                    </Col>
                    <Col span={8} className={styles.name}>已选{}项</Col>
                </Row>
                <div className={styles.wrap}>
                    {
                        renderSpecialItems(totalLength, shenPingFuList, ShenPingFu)
                    }
                    {
                        renderSpecialItems(totalLength, biFenList, BiFen)
                    }
                    {
                        renderSpecialItems(totalLength, zongJinQiuList, ZongJinQiu)
                    }
                    {
                        renderSpecialItems(totalLength, banQuanChangList, BanQuanChang)
                    }
                </div>
            </div>
        </ActionSheet>
    )

}

export default observer(BeiTiSelectedListActionSheet);
