import React, {useEffect, useRef, useState} from 'react';
import {Button, Space, Price, Divider, ConfigProvider, InputNumber, Badge, Ellipsis, Toast} from "@nutui/nutui-react";
import {ArrowUp} from '@nutui/icons-react'
import styles from '../index.module.less';
import {
    ColorPrimary,
    ColumnFlexTopLeft,
    fontColorWeight, NutUICustom,
    RowFlexJustifyCenter,
    RowFlexLeftCenter
} from "../../../theme/shortStyle";
import BeiTiSelectedListActionSheet from "./selectedListActionSheet";
import BeiTiGuoGuanWayActionSheet from "./guoGuanWayActionSheet";
import { observer } from 'mobx-react';
import {formatJingZuList, getSelectedItem, getSelectedItemCounts} from "../service";
import store from "../../../store";
import BeiTiPoster from "../poster";
import {getJingCaiPrice, getPass, saveJingZuRecord} from "../../../request";
import eventManage, {SELECT_JING_ZU} from "../../../event";
import {PASS_WAY} from "../../../constant";


function BeiTiSettlementDesk(props) {

    const [selectedListActionSheetVisible, setSelectedListActionSheetVisible] = useState(false);
    const [guoGuanWayActionSheetVisible, setGuoGuanWayActionSheetVisible] = useState(false);
    const [multi, setMulti] = useState(1);
    const [passList, setPassList] = useState([]);
    const [freePassList, setFreePassList] = useState([]);
    const [passListSelected, setPassListSelected] = useState([]);
    const [passType, setPassType] = useState(PASS_WAY.MN);
    const [priceInfo, setPriceInfo] = useState({});
    const [showPoster, setShowPoster] = useState({show: false, info: {}});

    const multiRef = useRef(1);
    const passListSelectedRef = useRef([]);

    const selectedCounts = getSelectedItemCounts(store.jingZuStore);

    const handleOpenSelectedList = () => {
        const count = getSelectedItemCounts(store.jingZuStore);
        if (count < 1) {
            return;
        }
        setSelectedListActionSheetVisible(true);
    };

    const handleOpenGuoGuanWay = () => {
        if (passList && passList.length > 0) {
            setGuoGuanWayActionSheetVisible(true);
        }
    };

    const handleShowPoster = () => {
        const selectedItem = getSelectedItem(store.jingZuStore);
        const chooseCmds = formatJingZuList(selectedItem);
        const passResult = passListSelected.join(';');
        const { amount, betsNum, maxPrize, minPrize } = priceInfo;
        const params = { chooseCmds, multi: multiRef.current, passResult, amount, betsNum, maxPrize, minPrize };
        saveJingZuRecord(params)
        .then(data => {
            setShowPoster({show: true, info: params });
        })
    };

    const handleFetchPass = () => {
       const selectedItem = getSelectedItem(store.jingZuStore);
       const chooseCmds = formatJingZuList(selectedItem);
       const tasks = [];
       tasks[0] = getPass({ multi: multiRef.current, chooseCmds }).then(data => {
           if (data.describe) {
               Toast.show({ content: data.describe })
           }
           setPassList(data.passList);
           if (passType === PASS_WAY.MN) {
               setPassListSelected([data.passList[0]]);
           }
       });
       tasks[1] = getPass({ multi: multiRef.current, chooseCmds, passWay: PASS_WAY.FREE }).then(data => {
           if (data.describe) {
               Toast.show({ content: data.describe })
           }
           setFreePassList(data.passList);
            if (passType === PASS_WAY.FREE) {
               setPassListSelected([data.passList[0]]);
            }
       });
       Promise.all(tasks).then(() => {
           setTimeout(() => {
               handleCalcPrice(passListSelectedRef.current);
           }, 100);
       })
    };

    const handleChangeMulti = (val) => {
        setMulti(val);
        handleFetchPass();
    };

    const handleCalcPrice = (passListSelected) => {
        const selectedItem = getSelectedItem(store.jingZuStore);
        const chooseCmds = formatJingZuList(selectedItem);
        const passResult = passListSelected.join(';');
        getJingCaiPrice({ chooseCmds, multi: multiRef.current, passResult }).then((data) => {
            data.amount = parseFloat(data.amount);
            data.betsNum = parseFloat(data.betsNum);
            data.maxPrize = parseFloat(data.maxPrize);
            data.minPrize = parseFloat(data.minPrize);
            setPriceInfo(data);
        })
    };

    const setPassListFn = (val) => {
        setPassListSelected(val);
        handleCalcPrice(val)
    };

    useEffect(() => {
        multiRef.current = multi;
    }, [multi]);

    useEffect(() => {
        passListSelectedRef.current = passListSelected;
    }, [passListSelected]);

    useEffect(() => {
        eventManage.on(SELECT_JING_ZU, handleFetchPass);
        return () => {
            eventManage.off(SELECT_JING_ZU, handleFetchPass);
        }
    }, []);

    return (
        <div className={`${styles.settlementDeskContainer} bei_ti_settle_desk`}>

            <div className={styles.settlementTop} style={RowFlexJustifyCenter}>
                <div style={RowFlexLeftCenter}>
                    <div className={styles.btn1} onClick={handleOpenSelectedList}>
                        <Badge value={selectedCounts} top={-2} right={-3}>
                            已选
                        </Badge>
                    </div>
                    <div className={styles.btn2} style={RowFlexLeftCenter}>
                        <div>过关</div>&nbsp;
                        <div
                            className={styles.input}
                            style={RowFlexLeftCenter}
                            onClick={handleOpenGuoGuanWay}
                        >
                            <div className={styles.left} style={fontColorWeight}>
                                {
                                    passListSelected.length > 0 &&
                                    <Ellipsis content={passListSelected.join(',')} direction="end" />
                                }
                                {
                                    passListSelected.length === 0 &&
                                    <span>请选择</span>
                                }
                            </div>
                            <div className={styles.right}>
                                <ArrowUp />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.btn3} style={RowFlexLeftCenter}>
                    <div>倍数</div>&nbsp;
                    <ConfigProvider theme={NutUICustom.NumberInput}>
                        <InputNumber value={multi} min={1} max={50} onChange={handleChangeMulti}/>
                    </ConfigProvider>
                </div>
            </div>

            <Divider style={{ margin: '12px 0'}}/>

            <div className={styles.settlementBottom} style={RowFlexJustifyCenter}>
                <div style={{...ColumnFlexTopLeft, fontSize: '16px'}}>
                    <Space align={'baseline'}>
                        <span style={fontColorWeight}>{priceInfo.betsNum ? priceInfo.betsNum : 0}注</span>
                        <Space align={'baseline'}>
                            <span style={{ ...fontColorWeight, color: ColorPrimary }}>{priceInfo.amount ? priceInfo.amount : 0}</span>
                            <span style={fontColorWeight}>元</span>
                        </Space>
                    </Space>
                    <Space align={'baseline'}>
                        <span style={fontColorWeight}>预计奖金</span>
                        <Space align={'baseline'}>
                            <Space align={'center'}>
                                <Price
                                    price={ priceInfo.minPrize ? parseFloat(priceInfo.minPrize) : 0 }
                                    size="normal"
                                    position="after"
                                    symbol=""
                                    thousands
                                    style={{fontSize: '10px', fontWeight: 700}}
                                />
                                ～
                                <Price
                                    price={ priceInfo.maxPrize ? parseFloat(priceInfo.maxPrize) : 0 }
                                    size="normal"
                                    position="after"
                                    symbol=""
                                    thousands
                                    style={{fontSize: '10px', fontWeight: 700}}
                                />
                            </Space>
                            <span style={fontColorWeight}>元</span>
                        </Space>
                    </Space>
                </div>
                <Button
                    size="large"
                    disabled={passListSelected.length < 1}
                    className={styles.submitBtn}
                    onClick={handleShowPoster}
                    fill="solid"
                >保存选择
                </Button>
            </div>

            <BeiTiSelectedListActionSheet
                visible={selectedListActionSheetVisible}
                setVisibleFn={setSelectedListActionSheetVisible}
            />

            <BeiTiGuoGuanWayActionSheet
                value={passListSelected}
                type={passType}
                visible={guoGuanWayActionSheetVisible}
                setVisibleFn={setGuoGuanWayActionSheetVisible}
                passList={passList}
                freePassList={freePassList}
                setPassListFn={setPassListFn}
                setPassType={setPassType}
            />

            {
                showPoster.show &&
                <BeiTiPoster info={showPoster.info} />
            }
        </div>
    )
}

export default observer(BeiTiSettlementDesk);
