import React, {useEffect, useRef, useState} from 'react';
import styles from '../index.module.less'
import {ActionSheet, Tabs, Row, Col, Checkbox, Space} from "@nutui/nutui-react";
import {PASS_WAY} from "../../../constant";

function Block(props) {
    const { option } = props;
    return (
        <Checkbox
            shape="button"
            value={option}
            label={
                <div className={styles.block} style={{ textAlign: 'center', height: '22px'}}>
                    <span >{option}</span>
                </div>
            }
            style={{ height: '42px'}}
        />
    )

}


export default function BeiTiGuoGuanWayActionSheet(props) {

    const { visible, setVisibleFn, passList, freePassList, setPassListFn, value, type, setPassType } = props;

    const [value1, setValue1] = useState([]);
    const [value2, setValue2] = useState([]);

    const typeRef = useRef();

    const handleChange1 = (val) => {
        setValue1(val);
        setPassListFn(val);
    };

    const handleChange2 = (val) => {
        setValue2(val);
        setPassListFn(val);
    };

    const calcHeight = () => {

        const findEle = (className) => {
            const elements = document.getElementsByClassName(className);
            const filteredElements = [];
            for (let i = 0; i < elements.length; i++) {
                if (elements[i].querySelector(".guo-guan-dialog")) {
                    filteredElements.push(elements[i]);
                }
            }
            return elements[1];
        };

        const bottomEle = document.querySelector('.bei_ti_settle_desk');
        const bottomHeight = bottomEle.getBoundingClientRect().height;
        const dialogEle = findEle('nut-popup-bottom');
        const overlayEle = document.querySelector('.nut-overlay');
        overlayEle.style.height = 'inherit';
        overlayEle.style.bottom = bottomHeight + 'px';
        dialogEle.style.bottom = bottomHeight + 'px';
    };

    useEffect(() => {
        if (typeRef.current === PASS_WAY.MN) {
            setValue1(value);
            setValue2([]);
        }
        if (typeRef.current === PASS_WAY.FREE) {
            setValue2(value);
            setValue1([]);
        }
    }, [value]);

    useEffect(() => {
        typeRef.current = type;
    }, [type]);

    useEffect(() => {
        if (visible) {
            setTimeout(() => {
                calcHeight();
            }, 100);
        }
    }, [visible]);

    return (
        <ActionSheet
            title={'过关方式'}
            className={'guo-guan-dialog'}
            visible={visible}
            onCancel={() => setVisibleFn(false)}
        >
            <div>
                <Tabs
                    value={type}
                    align="left"
                    onChange={(value) => {
                        setPassType(value);
                    }}
                >
                    <Tabs.TabPane title="M串N" value={PASS_WAY.MN}>
                        <Row justify={'start'} gutter={0}>
                            <Checkbox.Group
                                value={value1}
                                onChange={handleChange1}>
                                {
                                    passList.map((option, index) => {
                                        return (
                                            <Col key={index} span={5} offset={1}>
                                                <Block option={option} index={index}/>
                                            </Col>
                                        )
                                    })
                                }
                            </Checkbox.Group>
                        </Row>
                    </Tabs.TabPane>
                    <Tabs.TabPane title="自由过关" value={PASS_WAY.FREE}>
                        <Row gutter={10}>
                            <Checkbox.Group
                                value={value2}
                                onChange={handleChange2}>
                                {
                                    freePassList.map((option, index) => {
                                        return (
                                            <Col key={index} span={5} offset={1}>
                                                <Block option={option} index={index}/>
                                            </Col>
                                        )
                                    })
                                }
                            </Checkbox.Group>
                        </Row>
                    </Tabs.TabPane>
                </Tabs>
            </div>
        </ActionSheet>
    )
}
