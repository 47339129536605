import React, {useState} from 'react';
import styles from '../index.module.less'
import {ActionSheet, Tabs, Row, Col, Checkbox} from "@nutui/nutui-react";


const optionsMN = [
    {
        value: 1,
        label: '8×1'
    },
    {
        value: 2,
        label: '8×1'
    },
    {
        value: 3,
        label: '8×1'
    },
    {
        value: 4,
        label: '8×1'
    },
    {
        value: 5,
        label: '8×1'
    },
    {
        value: 6,
        label: '8×1'
    },
    {
        value: 7,
        label: '8×1'
    },
    {
        value: 8,
        label: '8×1'
    }
];

const optionsFree = [
    {
        value: 1,
        label: '1关'
    },
    {
        value: 2,
        label: '2关'
    },
    {
        value: 3,
        label: '3关'
    },
    {
        value: 4,
        label: '4关'
    }
];


function Block(props) {
    const { option } = props;
    return (
        <Checkbox
            className="test"
            shape="button"
            value={option.value}
            label={
                <div className={styles.block} style={{ textAlign: 'center'}}>
                    <span >{option.label}</span>
                    <span >{`{1.34}`}</span>
                    {
                        option.type === 1 &&
                        <></>
                    }
                </div>
            }
        />
    )

}

export default function BeiTiGuoGuanWayActionSheet(props) {

    const { visible, setVisibleFn } = props;
    const [tabValue, setTabValue] = useState('0');

    const [value1, setValue1] = useState([]);
    const [value2, setValue2] = useState([]);

    const handleSelectActionSheet = () => {

    };

    const handleChange1 = (val) => {
        setValue1(val)
    }

    const handleChange2 = (val) => {
        setValue2(val)
    }

    return (
        <ActionSheet
            title={'过关方式'}
            visible={visible}
            onSelect={handleSelectActionSheet}
            onCancel={() => setVisibleFn(false)}
        >
            <div>
                <Tabs
                    value={tabValue}
                    align="left"
                    onChange={(value) => {
                        setTabValue(value)
                    }}
                >
                    <Tabs.TabPane title="M串N">
                        <Row gutter={10}>
                            <Checkbox.Group
                                value={value1}
                                onChange={handleChange1}>
                                {
                                    optionsMN.map((option, index) => {
                                        return (
                                            <Col key={index} span={option.value === 0 ? 6 : 6} >
                                                <Block option={option}/>
                                            </Col>
                                        )
                                    })
                                }
                            </Checkbox.Group>
                        </Row>
                    </Tabs.TabPane>
                    <Tabs.TabPane title="自由过关">
                        <Row gutter={10}>
                            <Checkbox.Group
                                value={value2}
                                onChange={handleChange2}>
                                {
                                    optionsFree.map((option, index) => {
                                        return (
                                            <Col key={index} span={option.value === 0 ? 6 : 6} >
                                                <Block option={option}/>
                                            </Col>
                                        )
                                    })
                                }
                            </Checkbox.Group>
                        </Row>
                    </Tabs.TabPane>
                </Tabs>
            </div>
        </ActionSheet>
    )
}
