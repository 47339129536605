export function calcMainAreaHeight(selectors) {
    let height = 0;
    selectors.forEach(selector => {
        const ele = document.querySelector(selector);
        if (ele) {
            height = height + ele.getBoundingClientRect().height;
        }
    });
    return `calc(100vh - ${height}px)`;
}
