import React from 'react';
import styles from "../index.module.less";
import {Badge, Checkbox, Col, Row, Toast} from "@nutui/nutui-react";
import {ColorPrimary, ColumnFlexCenterCenter, fontColorWeight} from "../../../theme/shortStyle";
import { observer } from 'mobx-react';
import {useEffect, useState} from "react";
import {
    Jing_ZU_CATEGORY,
    Jing_ZU_SUB_CATEGORY
} from "../../../constant";
import {checkWhetherCanSetValue} from "../service";



const options1 = [
    {
        value: 0,
        label: '让球'
    },
    {
        value: 1,
        label: '主胜'
    },
    {
        value: 2,
        label: '平'
    },
    {
        value: 3,
        label: '客胜'
    }
]

const options2 = [
    {
        value: 0,
        label: '让球'
    },
    {
        value: 1,
        label: '主胜'
    },
    {
        value: 2,
        label: '平'
    },
    {
        value: 3,
        label: '客胜'
    }
]

function Block(props) {
    const { option, value, type } = props;
    let spfRatio = []
    if (type === Jing_ZU_SUB_CATEGORY.RANG_QIU) {
        spfRatio = value && value.rq ? value.rq.split(',').slice(1) : [];
    } else {
        spfRatio = value && value.spf ? value.spf.split(',') : [];
    }

    return (
        <Checkbox
            shape="button"
            value={option.value}
            disabled={option.value === 0}
            label={
                <div className={styles.block} style={{ textAlign: 'center'}}>
                    <span >
                        {
                            option.label === "让球" && type === Jing_ZU_SUB_CATEGORY.RANG_QIU &&
                            <span>
                                <span style={{ color: '#000'}}>让球</span>
                                {value.rq.split(',')[0]}
                            </span>
                        }
                        {
                            !(option.label === "让球" && type === Jing_ZU_SUB_CATEGORY.RANG_QIU) &&
                            <span>
                                <span style={ {color: option.label === "让球" ? '#000' : ''} }>{option.label}</span>
                                {
                                    option.label === "让球" &&
                                    <span>0</span>
                                }
                            </span>
                        }
                    </span>
                    {
                        option.value !== 0 &&
                        <span >{spfRatio[option.value-1]}</span>
                    }
                    {
                        option.type === 1 &&
                        <></>
                    }
                </div>
            }
        />
    )

}

function BeiTiShenPingFu(props) {

    const { showTitle, issue, value, setValueFn, children } = props;

    const [checked1, setChecked1] = useState([]);
    const [checked2, setChecked2] = useState([]);


    const handleChange1 = (val) => {
        const pass = checkWhetherCanSetValue({ issue, id: value.id, category: Jing_ZU_CATEGORY.SHEN_PING_FU });
        if(!pass) return;

        setChecked1(prevState => {
            if (checked2.filter(val => !!val).length > 0) {
                Toast.show({ content: '选择多种玩法，要再开一单进行选择哦' })
                return prevState;
            } else {
                handleUpdateValue(val, checked2);
                return val;
            }
        });
    };
    const handleChange2 = (val) => {
        const pass = checkWhetherCanSetValue({ issue, id: value.id, category: Jing_ZU_CATEGORY.SHEN_PING_FU });
        if(!pass) return;

        setChecked2(prevState => {
            if (checked1.filter(val => !!val).length > 0) {
                Toast.show({ content: '选择多种玩法，要再开一单进行选择哦' });
                return prevState;
            } else {
                handleUpdateValue(checked1, val);
                return val;
            }
        });
    };

    const handleUpdateValue = (c1, c2) => {
        const checked1Count = c1.filter(val => !!val).length;
        const checked2Count = c2.filter(val => !!val).length;
        if (checked1Count > 0) {
            setValueFn({ issue, id: value.id, checked: c1, category: Jing_ZU_CATEGORY.SHEN_PING_FU, subCategory: Jing_ZU_SUB_CATEGORY.BU_RANG_QIU});
        } else if (checked2Count > 0) {
            setValueFn({ issue, id: value.id, checked: c2, category: Jing_ZU_CATEGORY.SHEN_PING_FU, subCategory: Jing_ZU_SUB_CATEGORY.RANG_QIU});
        } else {
            setValueFn({ issue, id: value.id, checked: [], category: Jing_ZU_CATEGORY.SHEN_PING_FU, subCategory: Jing_ZU_SUB_CATEGORY.BU_RANG_QIU});
        }
    };


    useEffect(() => {
        if (Object.keys(value).length > 0) {
          if (value.category === Jing_ZU_CATEGORY.SHEN_PING_FU) {
              if (value.subCategory === Jing_ZU_SUB_CATEGORY.BU_RANG_QIU) {
                  setChecked1(value.checked);
                  setChecked2([]);
                  return;
              }
              if (value.subCategory === Jing_ZU_SUB_CATEGORY.RANG_QIU) {
                  setChecked2(value.checked);
                  setChecked1([]);
                  return;
              }
          }
        }
        setChecked1([]);
        setChecked2([]);
    }, [value]);

    return (
        <>
            {
                showTitle &&
                <div style={{...fontColorWeight, textAlign: 'center', marginBottom: '14px'}}>
                    胜平负
                    {
                        value.ifShowDan &&
                        <span style={{color: ColorPrimary, verticalAlign: 'bottom', marginLeft: '3px'}}>单</span>
                    }
                </div>
            }
            <Row gutter="10" className={styles.bottom} align={'flex-end'}>
                <Col span={22}>
                    <Row gutter={8}>
                        <Checkbox.Group
                            value={checked1}
                            onChange={handleChange1}>
                            {
                                options1.map(option => {
                                    return (
                                        <Col key={option.value} span={option.value === 0 ? 6 : 6} >
                                            <Block option={option} value={value}/>
                                        </Col>
                                    )
                                })
                            }
                        </Checkbox.Group>
                    </Row>
                    <Row gutter={8}>
                        <Checkbox.Group
                            value={checked2}
                            onChange={handleChange2}>
                            {
                                options2.map(option => {
                                    return (
                                        <Col key={option.value} span={option.value === 0 ? 6 : 6} >
                                            <Block option={option} value={value} type={Jing_ZU_SUB_CATEGORY.RANG_QIU}/>
                                        </Col>
                                    )
                                })
                            }
                        </Checkbox.Group>
                    </Row>
                </Col>
                {
                    !!children &&
                    children
                }
            </Row>
        </>
    )

}

export default  observer(BeiTiShenPingFu);
