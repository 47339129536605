import React, {useEffect, useState} from 'react';
import {ActionSheet, Button} from "@nutui/nutui-react";
import BeiTiTitle from "./title";
import BeiTiShenPingFu from "./category/shenPingFu";
import BeiTiBiFen from "./category/biFen";
import BeiTiZongJinQiu from "./category/zongjinQiu";
import BeiTiBanQuanChang from "./category/banQuanChang";
import styles from './index.module.less';
import {getBeiTiStoreItem} from "../../store";



export default function MoreModeActionSheet(props) {

    const { info, setValueFn, setMoreModeDialogInfoFn  } = props;

    const [title, setTitle] = useState('更多玩法');


    useEffect(() => {
    }, []);

    return (
        <ActionSheet
            title={title}
            visible={info.show}
            onCancel={() => setMoreModeDialogInfoFn({ show: false })}
        >
            <div className={styles.moreModeContainer}>
                <div className={styles.main}>
                    <BeiTiTitle/>
                    <BeiTiShenPingFu
                        showTitle={true}
                        value={getBeiTiStoreItem(info.id) || {}}
                        setValueFn={setValueFn}
                    />
                    <br/>
                    <BeiTiBiFen
                        showTitle={true}
                        value={getBeiTiStoreItem(info.id) || {}}
                        setValueFn={setValueFn}
                    />
                    <br/>
                    <BeiTiZongJinQiu
                        showTitle={true}
                        value={getBeiTiStoreItem(info.id) || {}}
                        setValueFn={setValueFn}
                    />
                    <br/>
                    <BeiTiBanQuanChang
                        showTitle={true}
                        value={getBeiTiStoreItem(info.id) || {}}
                        setValueFn={setValueFn}
                    />
                </div>
                <div style={{ margin: '18px 0'}}>
                    <Button>确定</Button>
                </div>
            </div>
        </ActionSheet>
    )
}
