import store from './index';
import {action, toJS} from "mobx";
import {Jing_ZU_CATEGORY, Jing_ZU_SUB_CATEGORY} from "../constant";
import eventManage, {SELECT_JING_ZU} from '../event/index';


export function initJingZuStore(issue, list) {
    const arr = list.map(item => {
        return {
            id: item.matchId,
            category: Jing_ZU_CATEGORY.SHEN_PING_FU,
            subCategory: Jing_ZU_SUB_CATEGORY.RANG_QIU,
            checked: [],
            issue,
            ...item
        }
    });

    action(() => {
        store.jingZuStore[issue] = arr;
    })();
}

export function setJingZuStore(issue, id, { category, subCategory, checked }) {
    const targetItem = {
        id,
        category,
        subCategory,
        checked
    };
    const jingZuStore = toJS(store.jingZuStore);
    const arr = jingZuStore[issue];
    const index = arr.findIndex(it => it.id === id);
    arr[index] = { ...arr[index], ...targetItem };
    action(() => {
        store.jingZuStore[issue] = arr;
    })();
    eventManage.emit(SELECT_JING_ZU);
}

export function updateJingZuStoreItemChecked(issue, id, deleteChecked) {
    const item = getJingZuStoreItem(issue, id);
    const checked = item.checked.filter(it => it !== deleteChecked);
    const arr = toJS(store.jingZuStore)[issue];
    const index = arr.findIndex(it => it.id === id);
    arr[index] = {...toJS(item), checked };
    action(() => {
        store.jingZuStore[issue] = arr;
    })();
    eventManage.emit(SELECT_JING_ZU);
}

export function getJingZuStoreItem(issue, id) {
    return store.jingZuStore[issue].find(it => it.id === id) || {};
}

export function clearJingZuStoreChecked() {

    let issueList = [];
    Object.keys(toJS(store.jingZuStore)).forEach(key => {
        if (store.jingZuStore[key]) {
            const arr = store.jingZuStore[key].filter(it => it.checked.filter(i => typeof i === 'number').length > 0);
            if(arr.length > 0) {
                issueList.push(key);
            }
        }
    });

    issueList.forEach(issue => {
        const arr = toJS(store.jingZuStore)[issue];
        arr.forEach(item => {
            item.checked = [];
            item.category = Jing_ZU_CATEGORY.SHEN_PING_FU;
            item.subCategory = '';
        });
        action(() => {
            store.jingZuStore[issue] = arr;
        })();
    });
    eventManage.emit(SELECT_JING_ZU);
}
