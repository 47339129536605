import http from "./http";
import {sessionCache} from "../utils/storage";
import {PASS_WAY} from "../constant";


export function getSmsCode(phone) {
    return http.get(`/user/getVerifyCode?phoneNumber=${phone}`);
}

export function login({ phone, code }) {
    return http.post(`/user/userLogin`, { phoneNumber: phone, code });
}

export function getList({ helperType, issue }) {
    return http.get(`/bets/matchList?helperType=${helperType}&issue=${issue || ''}`)
}

export function getRecentIssue({ helperType }) {
    return http.get(`/bets/obtainIssueNum?helperType=${helperType}`)
}

export function getUnitPrice({ helperType, userResult, multi = 1 }) {
    return http.get(`/bets/ctzq/buyPrice?helperType=${helperType}&userResult=${userResult}&multi=${multi}`)
}

export function getIssues({ helperType }) {
    return http.get(`/bets/getIssue?helperType=${helperType}`)
}

export function saveSelectedData({ userId, helperType, issue, startTime, endTime,  drawTime, traditionalMatchesList, userResult, betsNum, amount, multi = 1  }) {
    return http.post('/bets/ctzq/saveRecord', {
        userId,
        helperType,
        issue,
        startTime,
        endTime,
        drawTime,
        traditionalMatchesList,
        userResult,
        betsNum,
        amount,
        multi
    })
}

export function getUserQrCode() {
    const userId = sessionCache.getUserInfo().userId;
    return http.get(`/bets/obtainBingUserUrl/${userId}`)
}

export function getPass({ chooseCmds, multi, passWay = PASS_WAY.MN }) {
    // "chooseCmds": [
    //     {
    //         "matchId": 0,
    //         "comp": "string",
    //         "home": "string",
    //         "away": "string",
    //         "matchTime": "2024-05-31T14:51:28.739Z",
    //         "week": "string",
    //         "playType": 0,
    //         "sellStatus": "string",
    //         "result": "string"
    //     }
    // ],
    const params = {
        userId: sessionCache.getUserInfo().userId,
        chooseCmds,
        multi,
        passWay
    };
    return http.post('/bets/jingcai/getPass', params);
}

export function getJingCaiPrice({ chooseCmds, multi, passResult, passWay = PASS_WAY.MN}) {
    const params = {
        userId: sessionCache.getUserInfo().userId,
        chooseCmds,
        multi,
        passResult,
        passWay
    };
    return http.post('/bets/jingcai/buyPrice', params)
}

export function saveJingZuRecord({ chooseCmds, multi, passResult, passWay = PASS_WAY.MN, amount, betsNum, maxPrize, minPrize}) {
    const params = {
        userId: sessionCache.getUserInfo().userId,
        chooseCmds,
        multi,
        passResult,
        passWay,
        amount,
        betsNum,
        maxPrize,
        minPrize
    };
 return http.post('/bets/jingcai/saveRecord', params)
}

export function getHistory() {
    const userId = sessionCache.getUserInfo().userId;
    return http.get(`/bets/query/UserRecord?userId=${userId}&page=0&size=${100}`)
}

export function bindCustomerCode(code) {
    return http.post(`/user/bind?code=${code}`)
}
