import React, {useEffect, useState} from 'react';
import styles from './index.module.less'
import {getHistory} from "../../request";
import {CATEGORY, CATEGORY_LABEL_MAP} from "../../constant";
import {
    ColumnFlexTopRight,
    RowFlexJustifyCenter,
    RowFlexRightCenter
} from "../../theme/shortStyle";
import BeiTiPoster from "../../components/jingZu/poster";
import { Row, Col } from "@nutui/nutui-react";
import {ArrowSize6} from '@nutui/icons-react';
import RenJiuPoster from "../../components/renJiu/poster";
import RenShiSiPoster from "../../components/shengfuCai/poster";
import dayjs from "dayjs";
import {getWeekday} from "../../utils/util";
import NoData from "../../components/widget/nodata/noData";


function getMatchInfo(info) {
    if (info.traditionalMatchesList) {
        return info.traditionalMatchesList[0];
    }
    if (info.chooseCmds) {
        return info.chooseCmds[0];
    }
}

function Poster (props) {
    const { info } = props;
    const { helperType } = info;
    if (helperType === CATEGORY.JING_ZU.key) {
       return <BeiTiPoster info={info}/>
    }
    if (helperType === CATEGORY.REN_JIU.key) {
        return <RenJiuPoster info={info}/>
    }
    if (helperType === CATEGORY.REN_SHISI.key) {
        return <RenShiSiPoster info={info}/>
    }
}


function Item(props) {
    const { info, onClick } = props;
    const matchInfo = getMatchInfo(info);
    return (
        <div style={RowFlexJustifyCenter} className={styles.item} onClick={() => onClick(info)}>
            <div>
                <div className={styles.category}>{CATEGORY_LABEL_MAP[info.helperType]}</div>
                <div>
                    <div>{matchInfo.home}&nbsp;VS&nbsp;{matchInfo.away}</div>
                    <div className={styles.date}>
                        {getWeekday(parseInt(matchInfo.matchTime))} &nbsp;
                        {dayjs(parseInt(matchInfo.matchTime)).format('MM-DD HH:mm')}
                    </div>
                </div>
            </div>
            <div style={ColumnFlexTopRight}>
                <div style={RowFlexRightCenter}>
                    <span style={{ fontSize: '18px'}}>{info.betsNum}</span>
                    <span style={{ fontSize: '18px'}}>注</span>
                </div>
                <div style={{...RowFlexRightCenter}}>
                    <span className={styles.price}>{info.amount}</span>元
                </div>
            </div>
        </div>
    )
}

export default function History(props) {

    const [list, setList] = useState([]);
    const [postInfo, setPostInfo] = useState({ show: false, info: {}});


    const handleClick = (info) => {
        setPostInfo({ show: true, info })
    };

    const handleClickIcon  = () => {
        window.history.go(-1);
    };

    useEffect(() => {
        getHistory().then(data => {
            setList(data.content);
        })
    }, []);

    return (
        <div>
            <div className={styles.container}>
                <Row className={styles.title} align={'center'} type={'flex'}>
                    <Col span={4} style={{ textAlign: 'left'}}>
                        <ArrowSize6 className={styles.icon} onClick={handleClickIcon}/>
                    </Col>
                    <Col span={16}>模拟投注历史</Col>
                </Row>
                <div className={styles.content}>
                    {
                        list.length > 0 &&
                        list.map((info, index) => {
                            return <Item key={index} info={info} onClick={handleClick}></Item>
                        })
                    }
                    {
                        !list || list.length < 1 &&
                        <NoData/>
                    }
                </div>
            </div>
            {
                postInfo.show &&
                <Poster info={postInfo.info} />
            }
        </div>
    )

}
