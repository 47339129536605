export default function IconClear(props) {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Frame">
                <path id="Vector" d="M19.3166 18.6828L18.1779 12.1172H18.5625C18.8719 12.1172 19.1211 11.868 19.1211 11.5586V7.43359C19.1211 7.12422 18.8719 6.875 18.5625 6.875H13.2773V2.96484C13.2773 2.65547 13.0281 2.40625 12.7188 2.40625H9.28125C8.97188 2.40625 8.72266 2.65547 8.72266 2.96484V6.875H3.4375C3.12813 6.875 2.87891 7.12422 2.87891 7.43359V11.5586C2.87891 11.868 3.12813 12.1172 3.4375 12.1172H3.82207L2.6834 18.6828C2.67695 18.715 2.6748 18.7473 2.6748 18.7773C2.6748 19.0867 2.92402 19.3359 3.2334 19.3359H18.7666C18.7988 19.3359 18.8311 19.3338 18.8611 19.3273C19.1662 19.2758 19.3703 18.9857 19.3166 18.6828ZM4.38281 8.37891H10.2266V3.91016H11.7734V8.37891H17.6172V10.6133H4.38281V8.37891ZM14.4375 17.832V14.4805C14.4375 14.3859 14.3602 14.3086 14.2656 14.3086H13.2344C13.1398 14.3086 13.0625 14.3859 13.0625 14.4805V17.832H8.9375V14.4805C8.9375 14.3859 8.86016 14.3086 8.76562 14.3086H7.73438C7.63984 14.3086 7.5625 14.3859 7.5625 14.4805V17.832H4.35703L5.32598 12.2461H16.6719L17.6408 17.832H14.4375Z" fill="#868686"/>
            </g>
        </svg>

    )
}
