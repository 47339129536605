import React, {useState} from 'react';
import {Button, Space, Price, Divider, ConfigProvider, InputNumber, Badge} from "@nutui/nutui-react";
import {ArrowUp} from '@nutui/icons-react'
import styles from '../index.module.less';
import {
    ColorPrimary,
    ColumnFlexTopLeft,
    fontColorWeight, NutUICustom,
    RowFlexJustifyCenter,
    RowFlexLeftCenter
} from "../../../theme/shortStyle";
import BeiTiSelectedListActionSheet from "./selectedListActionSheet";
import BeiTiGuoGuanWayActionSheet from "./guoGuanWayActionSheet";
import { observer } from 'mobx-react';
import {getSelectedItemCounts} from "../service";
import store from "../../../store";
import BeiTiPoster from "../poster";


function BeiTiSettlementDesk(props) {

    const [selectedListActionSheetVisible, setSelectedListActionSheetVisible] = useState(false);
    const [guoGuanWayActionSheetVisible, setGuoGuanWayActionSheetVisible] = useState(false);
    const [showPoster, setShowPoster] = useState(false);

    const handleOpenSelectedList = () => {
        const count = getSelectedItemCounts(store.beiTiStore);
        if (count < 1) {
            return;
        }
        setSelectedListActionSheetVisible(true);
    };

    const handleOpenGuoGuanWay = () => {
        setGuoGuanWayActionSheetVisible(true);
    };

    const handleShowPoster = () => {
        setShowPoster(true);
    }

    return (
        <div className={`${styles.settlementDeskContainer} bei_ti_settle_desk`}>

            <div className={styles.settlementTop} style={RowFlexJustifyCenter}>
                <div style={RowFlexLeftCenter}>
                    <div className={styles.btn1} onClick={handleOpenSelectedList}>
                        <Badge value={getSelectedItemCounts(store.beiTiStore)} top={-2} right={-3}>
                            已选
                        </Badge>
                    </div>
                    <div className={styles.btn2} style={RowFlexLeftCenter}>
                        <div>过关</div>&nbsp;
                        <div
                            className={styles.input}
                            style={RowFlexLeftCenter}
                            onClick={handleOpenGuoGuanWay}
                        >
                            <div className={styles.left} style={fontColorWeight}>
                                单关
                            </div>
                            <div className={styles.right}>
                                <ArrowUp />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.btn3} style={RowFlexLeftCenter}>
                    <div>倍数</div>&nbsp;
                    <ConfigProvider theme={NutUICustom.NumberInput}>
                        <InputNumber defaultValue={1} min={1} max={50}/>
                    </ConfigProvider>
                </div>
            </div>

            <Divider style={{ margin: '12px 0'}}/>

            <div className={styles.settlementBottom} style={RowFlexJustifyCenter}>
                <div style={ColumnFlexTopLeft}>
                    <Space align={'baseline'}>
                        <span style={fontColorWeight}>1注</span>
                        <Space align={'baseline'}>
                            <span style={{ ...fontColorWeight, color: ColorPrimary }}>2</span>
                            <span style={fontColorWeight}>元</span>
                        </Space>
                    </Space>
                    <Space align={'baseline'}>
                        <span style={fontColorWeight}>预计奖金</span>
                        <Space align={'baseline'}>
                            <Price
                                price={8888.01}
                                size="large"
                                position="after"
                                symbol=""
                                thousands
                            />
                            <span style={fontColorWeight}>元</span>
                        </Space>
                    </Space>
                </div>
                <Button
                    type="primary"
                    className={styles.submitBtn}
                    onClick={handleShowPoster}
                >保存选择
                </Button>
            </div>

            <BeiTiSelectedListActionSheet
                visible={selectedListActionSheetVisible}
                setVisibleFn={setSelectedListActionSheetVisible}
            />

            <BeiTiGuoGuanWayActionSheet
                visible={guoGuanWayActionSheetVisible}
                setVisibleFn={setGuoGuanWayActionSheetVisible}
            />

            {
                showPoster &&
                <BeiTiPoster/>
            }
        </div>
    )
}

export default observer(BeiTiSettlementDesk);
