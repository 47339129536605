export function sleep(t = 3000) {
    return new Promise((resolve) => {
     setTimeout(() => {
         resolve()
     }, t)
    })
}



export function getWeekday(date) {
    const weekdays = {
        0: '周日',
        1: '周一',
        2: '周二',
        3: '周三',
        4: '周四',
        5: '周五',
        6: '周六'
    };
    const weekday = new Date(date).getDay();
    return weekdays[weekday];
}

export function getQueryVariable(variable) {
    let query = window.location.search.substring(1);
    let vars = query.split('&');
    for (let i = 0; i < vars.length; i++) {
        let pair = vars[i].split('=');
        if (pair[0] === variable) {
            let value = decodeURIComponent(pair[1]);
            return value;
        }
    }
    return null;
}

