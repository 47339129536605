import React, {useEffect, useState} from 'react';
import {ActionSheet, Button} from "@nutui/nutui-react";
import BeiTiTitle from "./title";
import BeiTiShenPingFu from "./category/shenPingFu";
import BeiTiBiFen from "./category/biFen";
import BeiTiZongJinQiu from "./category/zongjinQiu";
import BeiTiBanQuanChang from "./category/banQuanChang";
import styles from './index.module.less';
import {getBeiTiStoreItem} from "../../store";
import {getJingZuStoreItem} from "../../store/jingZu";



export default function MoreModeActionSheet(props) {

    const { info, setValueFn, setMoreModeDialogInfoFn  } = props;

    const [title, setTitle] = useState('更多玩法');


    const calcHeight = () => {

        const findEle = (className) => {
            const elements = document.getElementsByClassName(className);
            const filteredElements = [];
            for (let i = 0; i < elements.length; i++) {
                if (elements[i].querySelector(".more-mode-dialog")) {
                    filteredElements.push(elements[i]);
                }
            }
            return elements[1];
        };

        const bottomEle = document.querySelector('.bei_ti_settle_desk');
        const bottomHeight = bottomEle.getBoundingClientRect().height;
        const dialogEle = findEle('nut-popup-bottom');
        const overlayEle = document.querySelector('.nut-overlay');
        overlayEle.style.height = 'inherit';
        overlayEle.style.bottom = bottomHeight + 'px';
        dialogEle.style.bottom = bottomHeight + 'px';
    };

    useEffect(() => {
        if (info.show) {
            setTimeout(() => {
                calcHeight();
            }, 0);
        }
    }, [info.show]);

    return (
        <ActionSheet
            title={title}
            className={'more-mode-dialog'}
            visible={info.show}
            onCancel={() => setMoreModeDialogInfoFn({ show: false })}
        >
            <div className={styles.moreModeContainer}>
                <div className={styles.main}>
                    <BeiTiTitle value={getJingZuStoreItem(info.issue, info.id) || {}}/>
                    <BeiTiShenPingFu
                        showTitle={true}
                        issue={info.issue}
                        value={getJingZuStoreItem(info.issue, info.id) || {}}
                        setValueFn={setValueFn}
                    />
                    <br/>
                    <BeiTiBiFen
                        showTitle={true}
                        issue={info.issue}
                        value={getJingZuStoreItem(info.issue, info.id) || {}}
                        setValueFn={setValueFn}
                    />
                    <br/>
                    <BeiTiZongJinQiu
                        showTitle={true}
                        issue={info.issue}
                        value={getJingZuStoreItem(info.issue, info.id) || {}}
                        setValueFn={setValueFn}
                    />
                    <br/>
                    <BeiTiBanQuanChang
                        showTitle={true}
                        issue={info.issue}
                        value={getJingZuStoreItem(info.issue, info.id) || {}}
                        setValueFn={setValueFn}
                    />
                </div>
                {/*<div style={{ margin: '18px 0'}}>*/}
                {/*    <Button>确定</Button>*/}
                {/*</div>*/}
            </div>
        </ActionSheet>
    )
}
