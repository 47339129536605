import React, {useEffect, useState} from 'react';
import styles from '../index.module.less'
import {ActionSheet, Divider, Row, Col} from "@nutui/nutui-react";
import {Minus, CartRemove} from '@nutui/icons-react'
import {
    ColumnFlexCenterCenter, fontColorSubtype,
    fontColorWeight,
    RowFlexCenterCenter,
    RowFlexLeftCenter
} from "../../../theme/shortStyle";
import {BEI_TI_CATEGORY, BEI_TI_SUB_CATEGORY, BQC_MAP, Jing_ZU_SUB_CATEGORY, SPF_MAP} from "../../../constant";
import store from "../../../store";
import {getSelectedItemCounts, getSpecialCategoryList} from "../service";
import { observer } from 'mobx-react';
import {clearJingZuStoreChecked, updateJingZuStoreItemChecked} from "../../../store/jingZu";
import {toJS} from "mobx";
import {getWeekday} from "../../../utils/util";
import dayjs from "dayjs";
import IconClear from "../../widget/iconClear";

function Title (props) {

    const { data } = props;

    return (
        <div>
            <div style={{...RowFlexLeftCenter}}>
                <span>[主]</span>&nbsp;
                <span style={{...fontColorWeight, fontSize: '20px' }}>{data.home}</span>
                <span style={{...fontColorWeight, fontSize: '20px'}}>&nbsp;VS&nbsp;</span>
                <span style={{...fontColorWeight, fontSize: '20px'}}>{data.away}</span>&nbsp;&nbsp;
                <span className={styles.badge}>{data.comp}</span>
            </div>
            <div className={styles.date}>
                {getWeekday(parseInt(data.matchTime))} &nbsp;
                {dayjs(parseInt(data.matchTime)).format('MM-DD HH:mm')}
            </div>
        </div>
    )
}

function ShenPingFu(props) {

    const { data } = props;

    const rQ = data.rq.split(',');
    const sPF = data.spf.split(',');

    const handleDeleteItem = (issue, id, deleteCheckedItem) => {
        updateJingZuStoreItemChecked(issue, id, deleteCheckedItem);
    };

    return (
        <div>
            <Title data={data}/>
            <div style={{...RowFlexLeftCenter, flexWrap: 'wrap'}} >
                {
                    data.checked.map((checked) => {
                        return (
                            <div key={checked} style={RowFlexLeftCenter}  className={styles.block}>
                                <div style={RowFlexCenterCenter} className={styles.category}>
                                    <div>让球</div>
                                    <div className={styles.value} style={fontColorSubtype}>&nbsp;
                                        { data.subCategory === BEI_TI_SUB_CATEGORY.RANG_QIU ? '-1' : '0' }
                                    </div>
                                </div>
                                <div style={RowFlexCenterCenter} className={styles.main}>
                                    <div>
                                        {
                                            SPF_MAP[checked-1]
                                        }
                                    </div>&nbsp;
                                    <div className={styles.value} style={fontColorSubtype}>
                                        {
                                            data.subCategory === Jing_ZU_SUB_CATEGORY.RANG_QIU ? rQ[checked-1] : sPF[checked-1]
                                        }
                                    </div>
                                </div>
                                <Minus className={styles.deleteIcon} onClick={() => handleDeleteItem(data.issue, data.id, checked)}/>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

function BiFen(props) {
    const { data } = props;

    const bF = data.bf.split(',');


    const handleDeleteItem = (issue, id, deleteCheckedItem) => {
        updateJingZuStoreItemChecked(issue, id, deleteCheckedItem);
    };

    return (
        <div>
            <Title data={data}/>
            <div style={{...RowFlexLeftCenter, flexWrap: 'wrap'}} >
                {
                    data.checked.map(checked => {
                        return (
                            <div key={checked} style={{...RowFlexLeftCenter, width: '88px'}} className={styles.block}>
                                <div style={RowFlexCenterCenter} className={styles.main}>
                                    <div>{checked}:0</div>&nbsp;
                                    <div className={styles.value} style={fontColorSubtype}>{bF[checked]}</div>
                                </div>
                                <Minus className={styles.deleteIcon} onClick={() => handleDeleteItem(data.issue, data.id, checked)}/>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

function ZongJinQiu(props) {
    const { data } = props;

    const zJQ = data.jq.split(',');

    const handleDeleteItem = (issue, id, deleteCheckedItem) => {
        updateJingZuStoreItemChecked(issue, id, deleteCheckedItem);
    };
    return (
        <div>
            <Title data={data}/>
            <div style={{...RowFlexLeftCenter, flexWrap: 'wrap'}} >
                {
                    data.checked.map(checked => {
                        return (
                            <div key={checked} style={{...RowFlexLeftCenter, width: '80px'}}  className={styles.block}>
                                <div style={RowFlexCenterCenter} className={styles.main}>
                                    <div>{checked === 7 ? '7+' : checked}</div>&nbsp;
                                    <div className={styles.value} style={fontColorSubtype}>{zJQ[checked]}</div>
                                </div>
                                <Minus className={styles.deleteIcon} onClick={() => handleDeleteItem(data.issue, data.id, checked)}/>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

function BanQuanChang(props) {
    const { data } = props;

    const bQC = data.bqc.split(',');
    const handleDeleteItem = (issue, id, deleteCheckedItem) => {
        updateJingZuStoreItemChecked(issue, id, deleteCheckedItem);
    };

    return (
        <div>
            <Title data={data}/>
            <div style={{...RowFlexLeftCenter, flexWrap: 'wrap'}} >
                {
                    data.checked.map(checked => {
                        return (
                            <div key={checked} style={{...RowFlexLeftCenter, width: '80px'}} className={styles.block}>
                                <div style={RowFlexCenterCenter} className={styles.main}>
                                    <div>{BQC_MAP[checked]}</div>&nbsp;
                                    <div className={styles.value} style={fontColorSubtype}>{bQC[checked]}</div>
                                </div>
                                <Minus className={styles.deleteIcon} onClick={() => handleDeleteItem(data.issue, data.id, checked)}/>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

function BeiTiSelectedListActionSheet(props) {

    const { visible, setVisibleFn } = props;

    const [title, setTitle ] = useState('已选{}项');

    const handleSelectActionSheet = () => {

    };

    const handleClearAllChecked = () => {
        clearJingZuStoreChecked();
    };

    const totalLength = getSelectedItemCounts(store.jingZuStore);
    const shenPingFuList = getSpecialCategoryList(store.jingZuStore, BEI_TI_CATEGORY.SHEN_PING_FU);
    const biFenList = getSpecialCategoryList(store.jingZuStore, BEI_TI_CATEGORY.BI_FEN);
    const zongJinQiuList = getSpecialCategoryList(store.jingZuStore, BEI_TI_CATEGORY.ZONG_JING_QIU);
    const banQuanChangList = getSpecialCategoryList(store.jingZuStore, BEI_TI_CATEGORY.BAN_QUAN_CHANG);

    const calcHeight = () => {

        const findEle = (className) => {
            const elements = document.getElementsByClassName(className);
            const filteredElements = [];
            for (let i = 0; i < elements.length; i++) {
                if (elements[i].querySelector(".selected-list-dialog")) {
                    filteredElements.push(elements[i]);
                }
            }
            return elements[1];
        };

        const bottomEle = document.querySelector('.bei_ti_settle_desk');
        const bottomHeight = bottomEle.getBoundingClientRect().height;
        const dialogEle = findEle('nut-popup-bottom');
        const overlayEle = document.querySelector('.nut-overlay');
        overlayEle.style.height = 'inherit';
        overlayEle.style.bottom = bottomHeight + 'px';
        dialogEle.style.bottom = bottomHeight + 'px';
    };


    useEffect(() => {
        const data = toJS(store.jingZuStore);
        const issues = Object.keys(data);
        let hasChecked = false;
        issues.forEach(issue => {
            if (store.jingZuStore[issue].some(item => item.checked && item.checked.length > 0)) {
                hasChecked = true;
            }
        });
        if (!hasChecked) {
            setVisibleFn(false);
        }
    }, [totalLength]);

    useEffect(() => {
        if (visible) {
            setTimeout(() => {
                calcHeight();
            }, 100);
        }
    }, [visible]);

    const renderSpecialItems = (totalLength, list, ItemComp) => {
        return list.map((item, index) => {
            return (
                <div key={item.id}>
                    {<ItemComp data={item} />}
                    <Divider className={styles.divider}/>
                </div>
            )
        })
    }

    return (
        <ActionSheet
            title={''}
            className={'selected-list-dialog'}
            visible={visible}
            onSelect={handleSelectActionSheet}
            onCancel={() => setVisibleFn(false)}
        >
            <div className={styles.selectedListContainer}>
                <Row className={styles.title} align={'center'} type={'flex'}>
                    <Col span={8} className={styles.btnClean} onClick={handleClearAllChecked} style={RowFlexLeftCenter}>
                        {/*<CartRemove*/}
                        {/*    style={{ marginBottom: '-4px'}}*/}
                        {/*/>*/}
                        <IconClear/>
                        清除全部
                    </Col>
                    <Col span={8} className={styles.name}>已选{}项</Col>
                </Row>
                <div className={styles.wrap}>
                    {
                        renderSpecialItems(totalLength, shenPingFuList, ShenPingFu)
                    }
                    {
                        renderSpecialItems(totalLength, biFenList, BiFen)
                    }
                    {
                        renderSpecialItems(totalLength, zongJinQiuList, ZongJinQiu)
                    }
                    {
                        renderSpecialItems(totalLength, banQuanChangList, BanQuanChang)
                    }
                </div>
            </div>
        </ActionSheet>
    )

}

export default observer(BeiTiSelectedListActionSheet);
