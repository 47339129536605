const SELECT_JING_ZU = 'SelectJingZu';

export {
    SELECT_JING_ZU,
}

class EventManager {
    constructor() {
        this.events = {};
    }


    on(eventName, callback) {
        if (!this.events[eventName]) {
            this.events[eventName] = [];
        }
        this.events[eventName].push(callback);
    }

    emit(eventName, ...args) {
        const callbacks = this.events[eventName];
        if (!Array.isArray(callbacks)) {
            return;
        }
        callbacks.forEach(callback => {
            callback && callback(...args);
        })
    }

    off(eventName, callback) {
        if (!callback) {
            return;
        }
        const callbacks = this.events[eventName];
        if (!Array.isArray(callbacks)) {
            return;
        }
        this.events[eventName] = callbacks.filter(item => item !== callback);
    }

    once(eventName, callback) {
        const fn = function () {
            callback.apply(this, arguments);
            this.off(eventName, fn);
        }.bind(this);
        this.on(eventName, fn);
    }
}



const obj = new EventManager();
export default obj;
