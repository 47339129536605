import React from "react";
import {ColumnFlexCenterCenter, fontColorSubtype} from "../../../theme/shortStyle";

export default function NoData(props) {

    const { style = {} } = props;

    return (
        <div style={{...ColumnFlexCenterCenter, ...style}}>
            <svg t="1717304883422" className="icon" viewBox="0 0 1609 1024" version="1.1"
                 xmlns="http://www.w3.org/2000/svg" p-id="5274" width="200" height="200">
                <path
                    d="M1389.714286 719.238095v97.52381c0 48.761905-36.571429 73.142857-85.333334 73.142857H292.571429c-36.571429 0-60.952381-36.571429-60.952381-73.142857v-97.52381c-146.285714 36.571429-231.619048 85.333333-231.619048 121.904762 0 97.52381 365.714286 182.857143 804.571429 182.857143s804.571429-85.333333 804.571428-182.857143c0-36.571429-85.333333-85.333333-219.428571-121.904762z"
                    fill="#F5F5F5" p-id="5275"></path>
                <path
                    d="M1133.714286 341.333333c-48.761905 0-85.333333 36.571429-85.333334 85.333334 0 36.571429-36.571429 60.952381-73.142857 60.952381H633.904762s-73.142857 12.190476-73.142857-48.761905c0 0 0-97.52381-73.142857-97.52381H231.619048v475.428572c0 36.571429 24.380952 73.142857 60.952381 85.333333h999.619047c48.761905 12.190476 85.333333-24.380952 97.52381-73.142857V329.142857l-256 12.190476z"
                    fill="#FAFAFA" p-id="5276"></path>
                <path
                    d="M1401.904762 329.142857L1145.904762 36.571429C1121.52381 12.190476 1097.142857 0 1060.571429 0H548.571429c-24.380952 0-48.761905 12.190476-73.142858 24.380952L219.428571 329.142857v487.619048c-12.190476 36.571429 24.380952 85.333333 73.142858 97.523809h1024c24.380952 0 36.571429-12.190476 60.952381-12.190476 24.380952-12.190476 36.571429-36.571429 36.571428-60.952381V329.142857h-12.190476zM499.809524 48.761905c12.190476-12.190476 36.571429-12.190476 48.761905-12.190476h512c24.380952 0 48.761905 0 60.952381 24.380952l231.619047 268.190476H1133.714286c-24.380952 0-48.761905 12.190476-73.142857 36.571429-12.190476 12.190476-24.380952 36.571429-24.380953 60.952381s-24.380952 36.571429-48.761905 36.571428h-365.714285c-12.190476 0-24.380952 0-36.571429-12.190476 0 0-12.190476-12.190476-12.190476-24.380952 0-36.571429-24.380952-121.904762-97.52381-121.904762H256l243.809524-256z m865.523809 780.190476c0 24.380952-12.190476 36.571429-24.380952 48.761905-12.190476 12.190476-36.571429 12.190476-48.761905 12.190476H292.571429C256 877.714286 243.809524 853.333333 243.809524 816.761905V353.52381h243.809524c60.952381 0 60.952381 85.333333 60.952381 85.333333 0 12.190476 0 36.571429 12.190476 48.761905 12.190476 12.190476 36.571429 24.380952 60.952381 24.380952H975.238095c36.571429 0 73.142857-24.380952 85.333334-73.142857 0-24.380952 12.190476-36.571429 24.380952-48.761905 12.190476-12.190476 36.571429-24.380952 48.761905-24.380952h231.619047v463.238095z"
                    fill="#D9D9D9" p-id="5277"></path>
                <path
                    d="M499.809524 48.761905c12.190476-12.190476 36.571429-12.190476 48.761905-12.190476h512c24.380952 0 48.761905 0 60.952381 24.380952l231.619047 268.190476H1133.714286c-24.380952 0-48.761905 12.190476-73.142857 36.571429-12.190476 12.190476-24.380952 36.571429-24.380953 60.952381s-24.380952 36.571429-48.761905 36.571428h-365.714285c-12.190476 0-24.380952 0-36.571429-12.190476 0 0-12.190476-12.190476-12.190476-24.380952 0-36.571429-24.380952-121.904762-97.52381-121.904762H256l243.809524-256z"
                    fill="#FFFFFF" p-id="5278"></path>
            </svg>
            <span style={{...fontColorSubtype, fontSize: '18px'}}>暂无数据</span>
        </div>
    )
}
