import React, {useEffect, useState} from 'react';
import {Row, Col, Divider, Checkbox} from "@nutui/nutui-react";
import styles from './index.module.less'
import {fontColorSubtype, fontColorWeight} from "../../theme/shortStyle";
import dayjs from "dayjs";
import {getWeekday} from "../../utils/util";

const options = [
    {
        value: 3,
        label: '主胜'
    },
    {
        value: 1,
        label: '平'
    },
    {
        value: 0,
        label: '客胜'
    }
];

function Block(props) {
    const { option, value } = props;
    return (
        <Checkbox
          className="test"
          shape="button"
          value={option.value}
          label={
            <div className={styles.block} style={{ textAlign: 'center'}}>
                <span >{option.label}</span>
                <span style={{ color: value.includes(option.value) ? '#fff' : '#8a8a8a'}}>&nbsp;&nbsp;-</span>
                {
                    option.type === 1 &&
                    <></>
                }
            </div>
          }
        />
    )

}

export default function ShengfuCaiItem(props) {

    const { info, setValueFn } = props;


    const handleChange = (val) => {
        setValueFn(info.id, val);
    };

    return (
        <div className={styles.item}>
            <Row gutter="10" className={styles.top}>
                <Col span="4" style={{ textAlign: 'left'}}>
                    <span className={styles.badge}>{info.comp}</span>
                </Col>
                <Col span="16" style={{ textAlign: 'center'}}>
                    <span style={fontColorSubtype}>[主]</span>&nbsp;
                    <span style={{ fontWeight: 700 }}>
                        <span>{info.home}</span>&nbsp;VS&nbsp;<span>{info.away}</span>
                    </span>
                </Col>
            </Row>
            <div className={styles.middle}>
                {getWeekday(parseInt(info.matchTime))} &nbsp;
                {dayjs(parseInt(info.matchTime)).format('MM-DD HH:mm')}
            </div>
            <Row gutter="10" className={styles.bottom}>
                <Checkbox.Group
                    value={info.value}
                    onChange={handleChange}>
                    {
                        options.map(option => {
                            return (
                                <Col key={option.value} span="8" >
                                    <Block option={option} value={info.value}/>
                                </Col>
                            )
                        })
                    }
                </Checkbox.Group>
            </Row>
            <Divider style={{ margin: '12px 0'}}/>
        </div>
    )
}
