import React from 'react';
import styles from "./index.module.less";
import {Col, Row} from "@nutui/nutui-react";
import {fontColorSubtype} from "../../theme/shortStyle";

export default function BeiTiTitle() {
 return (
     <Row gutter="10" className={styles.top}>
         <Col span="4" style={{ textAlign: 'left'}}>
             <span className={styles.badge}>德甲</span>
         </Col>
         <Col span="16" style={{ textAlign: 'center'}}>
             <span style={fontColorSubtype}>[主]</span>&nbsp;
             <span style={{ fontWeight: 700 }}>
                        <span>{`门兴`}</span>&nbsp;VS&nbsp;<span>{`斯图加特`}</span>
                    </span>
         </Col>
     </Row>
 )
}
