import {action, observable, toJS} from "mobx";
import {BEI_TI_CATEGORY, BEI_TI_SUB_CATEGORY} from "../constant";

const store = observable({
    beiTiStore: [{id:'', category: '', subCategory: '', checked: []}],
    renJiuStore: {},
    jingZuStore: {}
});

export function initBeiTiStore(list) {

    const arr = list.map(item => {
        return {
            id: item.matchId,
            category: BEI_TI_CATEGORY.SHEN_PING_FU,
            subCategory: BEI_TI_SUB_CATEGORY.RANG_QIU,
            checked: [],
            ...item
        }
    });

    action(() => {
        store.beiTiStore = arr;
    })();
}

export function setBeiTiStore(id, { category, subCategory, checked }) {
    const targetItem = {
        id,
        category,
        subCategory,
        checked
    };
    const arr = toJS(store.beiTiStore);
    const index = arr.findIndex(it => it.id === id);
    arr[index] = { ...arr[index], ...targetItem };
    action(() => {
        store.beiTiStore = arr;
    })();
}

export function updateBeiTiStoreItemChecked(id, deleteChecked) {
    const item = getBeiTiStoreItem(id);
    const checked = item.checked.filter(it => it !== deleteChecked);
    const arr = toJS(store.beiTiStore);
    const index = arr.findIndex(it => it.id === id);
    arr[index] = {...toJS(item), checked };
    action(() => {
        store.beiTiStore = arr;
    })();
}

export function clearBeiTiStoreChecked() {
    const arr = toJS(store.beiTiStore);
    arr.forEach(item => {
        item.checked = [];
        item.category = BEI_TI_CATEGORY.SHEN_PING_FU;
        item.subCategory = '';
    });
    action(() => {
        store.beiTiStore = arr;
    })();
}

export function getBeiTiStoreItem(id) {
    return store.beiTiStore.find(it => it.id === id) || {};
}


export function setRenJiuStore(date, list) {
    action(() => {
        store.renJiuStore[date] = list;
    })();
}

export function setRenJiuStoreItem(date, id, value) {
    const renJiuData = toJS(store.renJiuStore);
    const renJiuCurData = renJiuData[date];
    renJiuCurData.matchesList = renJiuCurData.matchesList.map(it => {
        if(it.id === id) {
            it = {...it, value }
        }
        return it;
    });
    action(() => {
        store.renJiuStore = renJiuData;
    })()
}

export function getRenJiuStoreListByIssue(issue) {
    return store.renJiuStore[issue];
}

export default store;
