import React, {useEffect, useRef, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './index.module.less'
import { Input, Button, Checkbox, CountDown, Toast } from '@nutui/nutui-react'
import {ColumnFlexTopCenter, RowFlexLeftCenter} from "../../theme/shortStyle";
import {bindCustomerCode, getSmsCode, login} from "../../request";
import {sessionCache} from "../../utils/storage";
import {getQueryVariable} from "../../utils/util";


export default function Login() {

    const navigate = useNavigate();
    const [phone, setPhone] = useState('');
    const [code, setCode] = useState('');
    const [loading, setLoading] = useState(false);
    const [countDownLoading, setCountDownloading] = useState(false);

    const customerCode = getQueryVariable('c');

    const countDownRef = useRef();

    function handleGetSmsCode() {
        setCountDownloading(true);
        getSmsCode(phone).then(data => {
      }).catch(err => {
          console.error('er', err)
      })
    }

    function handleLogin() {
        if (loading) return;
        setLoading(true);
        login({ phone, code }).then(data => {
            sessionCache.setUserInfo(data);
            if (customerCode) {
                bindCustomerCode(customerCode).then(() => {
                    Toast.show({ content: '已成功绑定用户！' });
                    setTimeout(() => {
                        navigate('/');
                    }, 1300)
                })
            } else {
                navigate('/');
            }

        }).catch(err => {
            console.log('err', err)
        }).finally(() => {
            setLoading(false);
        })
    }

    function handleSetCode(val) {
        setCode(val);
    }

    function handleCountDownEnd() {
        setCountDownloading(false);
    }


    useEffect(() => {
        if (countDownLoading) {
            countDownRef.current.start();
        }
    }, [countDownLoading]);

    return (
        <div className={styles.container} style={ColumnFlexTopCenter}>
            <div className={styles.main}>
                <div className={styles.title}>手机号登录</div>
                <div
                    className={styles.item}
                    style={RowFlexLeftCenter}
                >
                    <Input type="digit" placeholder="输入手机号" value={phone} onChange={(val) => setPhone(val)}/>
                    <div className={styles.codeBtn}>
                        {
                            !countDownLoading &&
                            <Button
                                type="primary"
                                size="small"
                                fill="outline"
                                disabled={!phone}
                                onClick={handleGetSmsCode}
                                style={{
                                    borderColor: '#DADADA',
                                    color: '#3A7DFF',
                                    paddingLeft: '24px',
                                    paddingRight: '24px',
                                    marginRight: '10px',
                                    height: '32px'
                                }}
                            >
                                <span>获取验证码</span>
                            </Button>
                        }
                        {
                            countDownLoading &&
                            <Button
                            type="primary"
                            size="small"
                            fill="outline"
                            style={{
                            borderColor: '#DADADA',
                            color: '#3A7DFF',
                            paddingLeft: '24px',
                            paddingRight: '24px',
                            marginRight: '10px',
                            height: '32px'
                        }}
                            >
                                <CountDown
                                    autoStart={false}
                                    time={120000}
                                    ref={countDownRef}
                                    format="ss 秒"
                                    onEnd={handleCountDownEnd}
                                />
                            </Button>
                        }
                    </div>
                </div>
                <div className={styles.item}>
                    <Input type="digit" placeholder="输入验证码" disabled={!phone} onChange={handleSetCode}/>
                </div>
                <Button
                    size="large"
                    type="primary"
                    style={{ width: '100%', backgroundColor: '#E42323', fontSize: '22px', height: '55px', marginTop: '48px' }}
                    loading={false}
                    onClick={handleLogin}
                >
                    手机号快捷登录
                </Button>
                <div className={styles.checkbox}>
                    <Checkbox
                        className={'login'}
                        label={
                            <span className={styles.tips}>登录即表示同意<span style={{ color: '#000'}}>《乐富服务条款》</span>和<span style={{ color: '#000'}}>《乐富隐私条款》</span> </span>
                        }
                        defaultChecked={true}
                    />
                </div>
            </div>
        </div>
    )

}
