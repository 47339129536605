import React, {useEffect, useState} from 'react';
import {Space, Checkbox, ConfigProvider, Toast} from "@nutui/nutui-react";

import {fontColorWeight} from "../../../theme/shortStyle";
import styles from "../index.module.less";
import {BEI_TI_CATEGORY} from "../../../constant";
import {checkWhetherCanSetValue} from "../service";


function genOptions() {
    const classNameMap = {
        13: styles.sqt,
        18: styles.pqt,
        30: styles.fqt,
    }
    const options = [];
    for(let i = 0; i < 31; i++) {
        const obj = {
            value: i,
            label: `${i}:0`,
            ratio: 1.2,
            className: classNameMap[i] || ''
        };
        options.push(obj);
    }
    return options;
}

export default function BeiTiBiFen(props) {

    const { showTitle, value, setValueFn } = props;

    const [checked, setChecked] = useState([]);

    const handleChange = (val) => {
        const pass = checkWhetherCanSetValue({ id: value.id, category: BEI_TI_CATEGORY.BI_FEN });
        if (pass) {
            setChecked(val);
            setValueFn({ id:value.id, category: BEI_TI_CATEGORY.BI_FEN, subCategory: '', checked: val });
        }
    };

    useEffect(() => {
        if (Object.keys(value).length > 0) {
            const pass = checkWhetherCanSetValue({ id: value.id, category: BEI_TI_CATEGORY.BI_FEN, silence: true });
            if (pass) {
                setChecked(value.checked);
                return;
            }
        }
        setChecked([]);
    }, [value]);

    return (
        <div className={styles.biFenContainer}>
            {
                showTitle &&
                <div style={{...fontColorWeight, textAlign: 'center', marginBottom: '14px'}}>比分</div>
            }
                <Checkbox.Group
                    value={checked}
                    onChange={handleChange}>
                    <div className={styles.content}>
                        {
                            genOptions().map((option, index) => {
                                return (
                                    <div className={option.className} key={index}>
                                        <Checkbox
                                            className="bi-fen-checkbox"
                                            shape="button"
                                            value={option.value}
                                            label={
                                                <div  style={{ textAlign: 'center'}}>
                                                    <ConfigProvider
                                                        theme={{
                                                            nutuiSpaceGap: '2px',
                                                        }}
                                                    >
                                                        <Space direction="vertical">
                                                            <span >{option.label}</span>
                                                            <span >{option.ratio}</span>
                                                        </Space>
                                                    </ConfigProvider>
                                                </div>
                                            }
                                        />
                                    </div>
                                )
                            })
                        }
                    </div>
                </Checkbox.Group>
        </div>
    )
}
