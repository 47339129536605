import axios from "axios";
import {ERROR_RES_CODE} from "../request/errorCodes";
import {errorMessage} from "../components/widget/message";
import {sessionCache} from "../utils/storage";


const http = axios.create({
    baseURL: window.location.origin.includes('localhost') ? '/api' : window.location.origin + '/tapi',
});

http.interceptors.request.use(
    config => {
        // 自定义header信息（比如token）

        let token = '';

        if(config.url.indexOf('/user/userLogin') < 0 && config.url.indexOf('/user/getVerifyCode') < 0) {
            const userInfo = sessionCache.getUserInfo();
            if (!userInfo || !userInfo.token) {
                window.location.href = '/login';
                return;
            } else {
                token = userInfo.token;
            }
        }

        const headers = {
            ...config.headers,
            "Authorization": token
        };
        config.headers = headers;
        return config;
    }, function (error) {
        // 对请求错误做些什么
        return _handleNetworkError(error, '网络连接请求错误');
    });

http.interceptors.response.use(
    response => {
        // httpResponseHandle(response);
        return _handleResponse(response);
    },  error => {
        // alert(error);
        return _handleNetworkError(error, '网络连接响应错误');
    }
);


function _handleResponse(res) {
    if (res.config && res.config.responseType === "arraybuffer") {
        return res;
    }
    const result = res.data;
    if (result) {
        const code = result.code === undefined ? result.ret : result.code;
        const message = result.msg === undefined ? result.message : result.msg;
        if (code === 0 ) {
            return result.data;
        } else {
            return _handleResError(Number.isInteger(code) ? code : -2, message, res);
        }
    } else {
        return _handleResError(-1, '', res);
    }
}

function _handleNetworkError(error, title) {
    if (title) {
        errorMessage(title);
    }
    return Promise.reject(error);
}

function _handleResError(code, message, response) {
    let title, errorKey;
    if (code < 0) {
        title = '网络数据响应错误';
    } else {
        for (let key in ERROR_RES_CODE) {
            if (ERROR_RES_CODE[key] === code || (Array.isArray(ERROR_RES_CODE[key]) && ERROR_RES_CODE[key].includes(code))) {
                console.log('error code', code)
            }
        }

        if (!errorKey && !title) {
            title = '网络异常';
        }
    }

    if (title) {
        if (code) {
            title += `: ${code}`;
        }
        if(message) {
            title += `(${message})`;
        }
    }

    return _handleNetworkError(errorKey, title);
}

export default http;
