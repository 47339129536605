import React, {useState} from 'react';
import styles from './index.module.less'
import {TriangleDown, Clock} from '@nutui/icons-react'
import {ActionSheet, Space} from '@nutui/nutui-react'
import {CATEGORY} from "../../constant";


const options = [
    {
        name: CATEGORY.REN_JIU.name,
        key: CATEGORY.REN_JIU.key
    },
    {
        name: CATEGORY.REN_SHISI.name,
        key: CATEGORY.REN_SHISI.key
    },
    // {
    //     name: CATEGORY.BEI_TI.name,
    //     key: CATEGORY.BEI_TI.key
    // },
    {
        name: CATEGORY.JING_ZU.name,
        key: CATEGORY.JING_ZU.key
    }
];

function getCategoryName(key) {
    let name;
    Object.values(options).forEach(obj => {
        if (obj.key === key) {
            name = obj.name;
        }
    })
    return name;
}

export default function Header(props) {

    const { category, setCategoryFn } = props;

    const [actionSheetVisible, setActionSheetVisible] = useState(false);

    function handleSelectActionSheet(val) {
        setCategoryFn(val.key);
        setActionSheetVisible(false);
    }

    function handleCheckHistory() {
        window.location.href = '/history'
    }

    return (
        <div className={`${styles.container} lottery_header`}>
            <div className={styles.center} onClick={() => setActionSheetVisible(true)}>
                <Space align='center'>
                    { getCategoryName(category) }
                    <TriangleDown width={14} height={14}/>
                </Space>
            </div>
            <div className={styles.right} onClick={handleCheckHistory}>
                <Space align={'center'}>
                    <Clock width={18} height={18} style={{ marginTop: '5px'}}/>
                    历史
                </Space>
            </div>

            <ActionSheet
                visible={actionSheetVisible}
                options={options}
                onSelect={handleSelectActionSheet}
                cancelText="取消"
                onCancel={() => setActionSheetVisible(false)}
            />
        </div>
    )

}
