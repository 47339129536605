export const ColorPrimary = '#fa2c19';

export const ColumnFlexTopCenter = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
}

export const ColumnFlexCenterCenter = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
}

export const ColumnFlexTopLeft = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start'
};
export const ColumnFlexTopRight = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end'
}

export const ColumnFlexTopSpaceAround = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'space-around'
}

export const RowFlexLeftCenter = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
}

export const RowFlexRightCenter = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center'
};

export const RowFlexJustifyCenter  = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
};

export const RowFlexCenterCenter  = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
};

export const fontColorWeight = {
    color: '#000',
    fontWeight: 700
}

export const fontColorSubtype = {
    color: '#8A8A8A',
    fontWeight: 400
}

export const strongText = {
    color: '#F83758',
    fontWeight: 700
}

export const NutUICustom = {
    NumberInput: {
        nutuiInputnumberButtonWidth: '30px',
        nutuiInputnumberButtonHeight: '30px',
        nutuiInputnumberButtonBorderRadius: '2px',
        nutuiInputnumberButtonBackgroundColor: `#f4f4f4`,
        nutuiInputnumberInputHeight: '30px',
        nutuiInputnumberInputMargin: '0 2px',
    }
}
