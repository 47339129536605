import React, {useEffect, useRef, useState} from 'react';
import {Collapse, Popup} from "@nutui/nutui-react";
import styles from './index.module.less'
import {ArrowDown} from "@nutui/icons-react";
import { observer } from 'mobx-react';
import MoreModeActionSheet from "./moreModeActionSheet";
import BeiTiSettlementDesk from "./shoppingCart/settlementDesk";
import store from "../../store";
import {calcMainAreaHeight} from "../../service";
import {getList, getRecentIssue} from "../../request";
import {CATEGORY} from "../../constant";
import {initJingZuStore, setJingZuStore} from "../../store/jingZu";
import BeiTiItem from "./item";
import ArrowIcon from "../widget/svgIcon/arrowIcon";

function JingZu() {

    const [moreModeDialogInfo, setMoreModeDialogInfo] = useState({ show: false, issue: '', id: '' });
    const [dateOption, setDateOption] = useState([]);



    const contentRef = useRef();

    const updateChecked = ({issue, id, category, subCategory, checked}) => {
        setJingZuStore(issue, id, { category, subCategory, checked })
    };

    const setMainHeight = () => {
        const h = calcMainAreaHeight(['.lottery_header', '.bei_ti_settle_desk', '.nut-safe-area-position-bottom'])
        if(contentRef && contentRef.current) {
            contentRef.current.style.height = h;
        }
    };

    const handleChangeCollapse = (issue) => {
        if(!issue) return;
        if(store.jingZuStore[issue]) return;
        getList({helperType: CATEGORY.JING_ZU.key, issue}).then(data => {
            if (data && data.matchList && data.matchList.length > 0) {
                initJingZuStore(issue, data.matchList);
            }
        })
    };

    useEffect(() => {
            getRecentIssue({ helperType: CATEGORY.JING_ZU.key }).then(issuesList => {
                if (issuesList && issuesList.length > 0) {
                    setDateOption(issuesList.map(i => ({id: i.issue, name: `${i.describe}`})));
                    getList({helperType: CATEGORY.JING_ZU.key, issue: issuesList[0].issue}).then(data => {
                        if (data && data.matchList && data.matchList.length > 0) {
                            initJingZuStore(issuesList[0].issue, data.matchList);
                        }
                    })
                }
            });
        setMainHeight();
    }, []);

    return (
        <div className={styles.container}>
            <div className={styles.content} ref={contentRef}>
                {
                    dateOption.length > 0 &&
                    dateOption.map(date => {
                        return (
                            <Collapse
                                onChange={() => handleChangeCollapse(date.id)}
                                expandIcon={<ArrowIcon/>}
                                key={date.id}
                                defaultActiveName={[dateOption[0]?.id || '']}
                            >
                                <Collapse.Item title={date.name} name={date.id}>
                                    {
                                        store.jingZuStore[date.id] &&
                                        store.jingZuStore[date.id].map((item) => {
                                            return (
                                                <BeiTiItem
                                                    key={item.id}
                                                    value={item}
                                                    issue={date.id}
                                                    id={item.id}
                                                    setValueFn={updateChecked}
                                                    setMoreModeDialogInfoFn={setMoreModeDialogInfo}
                                                />
                                            )
                                        })
                                    }
                                    {
                                        (!store.jingZuStore[date.id] ||
                                        store.jingZuStore[date.id].length < 1) &&
                                        <div style={{ textAlign: 'center', color: '#8A8A8A' }}>暂无数据</div>
                                    }
                                </Collapse.Item>
                            </Collapse>
                        )
                    })
                }
            </div>

            {
                moreModeDialogInfo.show &&
                <MoreModeActionSheet
                    info={moreModeDialogInfo}
                    setMoreModeDialogInfoFn={setMoreModeDialogInfo}
                    setValueFn={updateChecked}
                />
            }

            <BeiTiSettlementDesk/>
        </div>
    )

}

export default observer(JingZu);
