import React, {useEffect, useRef, useState} from 'react';
import { createPortal } from 'react-dom';
import {Row, Col, Image, Button} from "@nutui/nutui-react";
import QRCode from 'qrcode';
import logoPng from '@/assets/le_fu_logo.png';
import styles from './index.module.less'
import {
    ColorPrimary,
    ColumnFlexTopSpaceAround,
    fontColorWeight,
    RowFlexCenterCenter,
    RowFlexJustifyCenter, strongText
} from "../../theme/shortStyle";
import html2canvas from "html2canvas";
import {CATEGORY, Jing_ZU_CATEGORY, JING_ZU_CATEGORY_LABEL} from "../../constant";
import {sessionCache} from "../../utils/storage";

const textAlignCenter = { textAlign: 'center' };
const paddingStyle = { padding: '7px' };
const cellStyle = {
    ...textAlignCenter,
    ...paddingStyle,
};
const fontLight = {
    color: '#8A8A8A',
};
const miniLightFont = {
    color: '#8A8A8A',
    fontsize: '10px',
};

function MatchItem(props) {
    const { item, info } = props;
    return (
        <>
            <Row className={styles.tableHeader}>
                <Col span={8} style={{ ...cellStyle, ...fontLight}}>
                    周六001[主]
                </Col>
                <Col span={12} style={{ ...cellStyle, ...fontColorWeight }}>
                    {item.home} VS {item.away}
                </Col>
                <Col span={4} style={{ ...cellStyle}}></Col>
            </Row>
            <Row className={styles.tableHeader}>
                <Col span={8} style={{ ...cellStyle, ...fontLight}}>
                    {
                        JING_ZU_CATEGORY_LABEL[item.playType]
                    }
                </Col>
                <Col span={12} style={{ ...cellStyle, ...fontColorWeight }}>
                    {
                        item.result.split(';').map((it, idx) => {
                            if (item.playType === Jing_ZU_CATEGORY.RANG_QIU && idx === 0) {
                                return <></>
                            }
                            return (
                                <>
                                    <span>{`${it.split(',')[0]}`}</span>
                                    <span style={miniLightFont}>{`(${it.split(',')[1]})`}</span>
                                </>
                            )
                        })
                    }
                </Col>
            </Row>
        </>
    )
}

function PosterBase(props) {
    const { info } = props;

    return (
        <div className={styles.base}>
            <div className={styles.item}>
                <div className={styles.block}>
                    <div>
                        <span className={styles.number}>3</span>
                        <span className={styles.unit}>场</span>&nbsp;&nbsp;
                        <span className={styles.number} style={{fontSize: '18px'}}>{info.passResult}</span>
                        {/*<span className={styles.unit}>关</span>*/}
                    </div>
                    <div className={styles.label}>过关</div>
                </div>
                <div className={styles.block}>
                    <div>
                        <span className={styles.number}>{info.multi}</span>
                        <span className={styles.unit}>倍</span>
                    </div>
                    <div className={styles.label}>倍数</div>
                </div>
            </div>
            <div className={styles.item}>
                <div className={styles.block}>
                    <div>
                        <span className={styles.number}>{info.betsNum}</span>
                        <span className={styles.unit}>注</span>&nbsp;&nbsp;
                        <span className={styles.number}>{info.amount}</span>
                        <span className={styles.unit}>元</span>
                    </div>
                    <div className={styles.label}>注数</div>
                </div>
                <div className={styles.block}>
                    <div className={styles.number}>
                        {info.minPrize}～{info.maxPrize}
                    </div>
                    <div className={styles.label}>预计金额</div>
                </div>
            </div>
        </div>
    )
}

function PosterDivider() {
    return (
        <div className={styles.divider}>
            <div className={styles.round1}></div>
            <div className={styles.liner}></div>
            <div className={styles.round2}></div>
        </div>
    )
}

function PosterDetail(props) {
    const { info } = props;

    return (
        <div className={styles.detail}>
            <div className={styles.detailTitle}>投注详情</div>
            <div className={styles.table}>
                <Row className={styles.tableHeader}>
                    <Col span={7} style={{ ...cellStyle }}>场次</Col>
                    <Col span={12} style={{ ...cellStyle}}>选择</Col>
                    <Col span={5} style={{ ...cellStyle}}>赛果</Col>
                </Row>
                {
                    info.chooseCmds.map((item, index) => {
                        return <MatchItem key={index} item={item} info={info}/>
                    })
                }
            </div>
        </div>
    )
}

export default function BeiTiPoster(props) {

    const { info } = props;

    const [qrCode, setQrCode] = useState('');
    const [qrCodeGenLoading, setQrCodeGenLoading] = useState(true);
    const posterRef = useRef();

    const genQrCode = () => {
        setQrCodeGenLoading(true);
        const loginUrl = window.location.origin + `/login?t=d&c=${sessionCache.getUserInfo().code}`;
        QRCode.toDataURL(loginUrl).then( base64 => {
            setQrCodeGenLoading(false);
            setQrCode(base64);
        });
    };

    const handleSavePoster = () => {
        if (posterRef.current) {
            html2canvas(posterRef.current).then(function(canvas) {
                const data = canvas.toDataURL( 'image/png', 1 );
                const a = document.createElement('a');
                a.href = data;
                a.download = '竞彩足球' + '期模拟投注.png';
                a.click();
                window.URL.revokeObjectURL(data);
            })
        }
    };

    const handleReload = () => {
        window.location.reload()
    };

    useEffect(() => {
        genQrCode();
    }, []);

    return createPortal(
        <div style={{ backgroundColor: ColorPrimary }}>
            <div className={styles.postWarp}>
                <div className={styles.posterContainer} ref={posterRef}>
                    <div className={styles.title} style={RowFlexJustifyCenter}>
                        <div style={RowFlexCenterCenter}>
                            <Image
                                width={48}
                                height={48}
                                src={logoPng}
                                radius={12}
                                style={{ marginRight: '8px'}}
                            />
                            <div style={{...ColumnFlexTopSpaceAround, height: '48px'}}>
                                <div style={{ fontSize: '16px', ...fontColorWeight, color: '#fff', textAlign: 'left' }}>乐富体育</div>
                                <div style={{ fontSize: '12px' }}>长按识别二维码 关注他的方案</div>
                            </div>
                        </div>
                        <div>
                            <Image
                                width={68}
                                height={68}
                                src={qrCode}
                            />
                        </div>
                    </div>
                    <div className={styles.main}>
                        <div className={styles.top}>
                            竞彩足球
                        </div>
                        <div className={styles.content}>
                            <PosterBase info={info}/>
                            <PosterDivider/>
                            <PosterDetail info={info}/>
                            <div className={styles.tips} style={{...strongText, fontSize: '10px' }}>
                                *投注助手不能购买彩票，如需购买，请到线下彩票站*注:单场投注，单注最高奖金限额为10万元:2或3场过关投注，单注最高奖金限额为20万元;4或5场过关投注，单注最高奖金限额为50万元;6场过关投注，单注最高奖金限额100万元。
                            </div>
                            <div className={styles.bottom} style={RowFlexCenterCenter}>
                                <div className={styles.round3}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${styles.btnGroup} poster-btn-group`} style={RowFlexCenterCenter}>
                <Button type="primary" fill="outline" style={{ marginRight: '16px'}} onClick={handleReload}>再来一单</Button>
                <Button type="primary" onClick={handleSavePoster} loading={qrCodeGenLoading}>保存</Button>
            </div>
        </div>,
        document.body
    )

}
